
  import { defineComponent } from "vue";
  import ItemTile from "@/components/ItemTile/ItemTile.vue";
  import Product from "@/components/Product/Product.vue";

  export default defineComponent({
    name: "ItemPreview",
    components: { ItemTile, Product },
    props: {
      itemId: {
        required: false,
        type: String,
        default: "",
      },
      itemName: {
        required: true,
        type: String,
      },
      photoUrl: {
        required: true,
        type: String,
      },
      itemColor: {
        required: true,
        type: String,
      },
      title: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    setup() {
      return {};
    },
  });
