import wardobeService from "@/services/wardobeService";
import { useMutation } from "vue-query";

function useAddNewWardobe() {
	return useMutation((wardobeName: string) =>
		wardobeService.createAndAddWardobeToUser(wardobeName)
	);
}

export default useAddNewWardobe;
