import { firestore } from "@/firebase";
import { Collection } from "@/types/collection/Collection";
import { CreateCollectionDTO } from "@/types/collection/dto/CreateCollectionDTO";

const getAllCollections = async (): Promise<Collection[] | undefined> => {
	const collection = await firestore.collection("collections").get();

	return collection.docs.map(
		(collection) => ({ ...collection.data(), id: collection.id } as Collection)
	);
};

const getUserCollection = async (
	userId: string
): Promise<Collection[] | undefined> => {
	const collection = await firestore
		.collection("collections")
		.where("designerId", "==", userId.trim())
		.get();

	return collection.docs.map(
		(collection) =>
			({
				...collection.data(),
				id: collection.id,
			} as Collection)
	);
};

const getCollection = async (id: string): Promise<Collection | undefined> => {
	const collection = await firestore
		.collection("collections")
		.doc(id.trim())
		.get();
	return { ...collection.data(), id: collection.id } as Collection | undefined;
};

const addNewCollection = async (
	collectionDTO: CreateCollectionDTO
): Promise<Collection | undefined> => {
	const collection = await firestore
		.collection("collections")
		.add({ ...collectionDTO, productsIds: [] });
	return await getCollection(collection.id);
};

const updateCollection = async (
	id: string,
	updatedFields: Partial<Collection>
): Promise<Collection | undefined> => {
	await firestore
		.collection("collections")
		.doc(id.trim())
		.update(updatedFields);

	return await getCollection(id);
};

const removeCollection = async (id: string): Promise<void> => {
	await firestore.collection("collections").doc(id.trim()).delete();
};

export default {
	getAllCollections,
	getUserCollection,
	getCollection,
	addNewCollection,
	updateCollection,
	removeCollection,
};
