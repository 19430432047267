
import { defineComponent, ref } from "vue";
import DesignerPageContainer from "@/components/DesignerPageContainer/DesignerPageContainer.vue";
import DashboardPageHeader from "@/components/DashboardPageHeader/DashboardPageHeader.vue";
import NewItem from "@/components/NewItem/NewItem.vue";
import { useField, useForm } from "vee-validate";
import AddNewCollectionSchema from "@/schemas/AddNewCollectionSchema";
import Input from "@/components/Input/Input.vue";
import Button from "@/components/Buttons/Button.vue";
import collectionService from "@/services/collectionService";
import alertService from "@/services/alertService";
import router from "@/router";

export default defineComponent({
	name: "EditCollectionView",
	components: {
		DesignerPageContainer,
		NewItem,
		Input,
		Button,
		DashboardPageHeader,
	},
	setup() {
		const { id } = router.currentRoute.value.params;

		const { handleSubmit, errors } = useForm({
			validationSchema: AddNewCollectionSchema,
		});

		const { value: collectionName } = useField<string>("collectionName");
		const { value: imageUrl } = useField<string>("imageUrl");

		const onSubmit = handleSubmit(async (values) => {
			try {
				const collection = await collectionService.updateCollection(
					id as string,
					{
						name: values.collectionName as string,
						photoUrl: values.imageUrl as string,
					}
				);

				if (!collection) {
					throw new Error("Error was occured. Try again");
				}

				alertService.success(
					`Collection ${values.collectionName} successfully created`
				);

				setTimeout(() => {
					router.push("/collection/" + collection?.id);
				}, 1000);
			} catch (e) {
				alertService.error(e);
			}
		});

		return {
			collectionName,
			imageUrl,
			errors,
			onSubmit,
		};
	},
});
