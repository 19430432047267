
  import { defineComponent, ref, Ref } from "vue";
  import { useField, useForm } from "vee-validate";
  import itemDescriptionSchema from "@/schemas/itemDescriptionSchema";
  import { Module } from "@/types/product/Module";
  import ItemTypeEnum from "@/enums/ItemTypeEnum";
  import { PredefinedModuleSelect } from "@/types/product/PredefinedModule";

  export default defineComponent({
    name: "GreenCredentailsModule",
    components: {},
    props: {
      options: {
        type: Array,
        required: true,
      },
    },
    setup(props, context) {
      const { handleSubmit, errors } = useForm({
        validationSchema: itemDescriptionSchema,
      });

      const predefinedModules: PredefinedModuleSelect[] =
        props.options as PredefinedModuleSelect[];

      const item_desc: Ref<string> = ref("");
      const { value: selectedModule } = useField("selectedModule");

      const onChange = handleSubmit(({ selectedModule }) => {
        if (selectedModule !== "") {
          const module: any = predefinedModules?.find(
            (module) => module.id === selectedModule
          );
          if (
            module?.moduleValue &&
            module.moduleType === ItemTypeEnum.greenCredentials
          ) {
            item_desc.value = module.moduleValue?.description || "";
          }
        }
        const module: Module = {
          type: ItemTypeEnum.greenCredentials,
          value: { description: item_desc.value },
        };
        context.emit("moduleSubmitted", module);
      });

      if (predefinedModules) {
        selectedModule.value = predefinedModules[0].id;
        onChange();
      }

      return { onChange, errors, selectedModule, item_desc };
    },
  });
