
import { computed, defineComponent } from "vue";
import Header from "@/components/Header/Header.vue";
import { UserGetters } from "@/store/user/getters";
import { useStore } from "vuex";
import { useQuery } from "vue-query";
import wardobeService from "@/services/wardobeService";
import Loader from "@/components/Loader/Loader.vue";
import Section from "@/components/Section/Section.vue";
import ProductSlider from "@/components/ProductSlider/ProductSlider.vue";
import UserViewsContainer from "@/components/UserViewsContainer/UserViewsContainer.vue";

export default defineComponent({
	name: "AllWardobes",
	components: {
		Header,
		Loader,
		Section,
		ProductSlider,
		UserViewsContainer,
	},
	setup() {
		const store = useStore();
		const user = computed(() => store.getters[UserGetters.GET_USER]);

		const { data: wardobes, status } = useQuery(
			"wardobes",
			() => wardobeService.getWardobesByUser(user.value?.id),
			{
				retry: true,
			}
		);

		return {
			wardobes,
			status,
		};
	},
});
