import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ebfef602"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nft-preview" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "gradient-bg",
        style: _normalizeStyle({
          backgroundImage: `url(${require('@/assets/backgroundGradientNFT.png')}`,
        })
      }, [
        _createElementVNode("img", {
          src: _ctx.metaverseAvatar,
          alt: "Item photo"
        }, null, 8, _hoisted_3)
      ], 4)
    ])
  ]))
}