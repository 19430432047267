
  import { defineComponent, ref } from "vue";

  export default defineComponent({
    name: "InputNumber",
    components: {},
    props: {
      label: {
        type: String,
        required: true,
      },
      checkValue: {
        type: Boolean,
        required: true,
      },
    },
    emits: ["changed"],
    setup(props) {
      const value = ref(props.checkValue);

      return { value };
    },
  });
