import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-735dbea8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { className: "account-switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('account-toggle', _ctx.AccountTypeEnum.user))),
      class: _normalizeClass([_ctx.account == _ctx.AccountTypeEnum.user ? 'switched' : '', 'switch-element left'])
    }, " User ", 2),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('account-toggle', _ctx.AccountTypeEnum.producer))),
      class: _normalizeClass([_ctx.account == _ctx.AccountTypeEnum.producer ? 'switched' : '', 'switch-element right'])
    }, " Producer ", 2)
  ]))
}