import productService from "@/services/productService";
import { useMutation } from "vue-query";

interface Data {
	id: string;
}

function useGetProductMutation() {
	return useMutation((id: string) => productService.getProduct(id));
}

export default useGetProductMutation;
