
  import { defineComponent, Ref, ref } from "vue";
  import { Module } from "@/types/product/Module";
  import ItemTypeEnum from "@/enums/ItemTypeEnum";
  import { PredefinedModuleSelect } from "@/types/product/PredefinedModule";

  export default defineComponent({
    name: "ResaleModule",
    components: {},
    props: {
      options: {
        type: Array,
        required: true,
      },
    },
    setup(props, context) {
      const predefinedModules: PredefinedModuleSelect[] =
        props.options as PredefinedModuleSelect[];

      const selectedModule: Ref<string> = ref("");
      const resaleInformation = ref();
      const onSelectedModuleChange = () => {
        if (selectedModule.value !== "") {
          const module: any = predefinedModules?.find(
            (module) => module.id === selectedModule.value
          );
          if (
            module?.moduleValue &&
            module.moduleType === ItemTypeEnum.resale
          ) {
            resaleInformation.value = module.moduleValue;
            onChange();
          }
        }
      };

      const onChange = () => {
        const module: Module = {
          type: ItemTypeEnum.resale,
          value: {
            item_info: resaleInformation.value.item_info,
            additions: resaleInformation.value.additions,
            condition: resaleInformation.value.condition,
            depth: resaleInformation.value.depth,
            detailed_material: resaleInformation.value.detailed_material,
            heel_height: resaleInformation.value.heel_height,
            height: resaleInformation.value.height,
            item_length: resaleInformation.value.item_length,
            lining_material: resaleInformation.value.lining_material,
            material: resaleInformation.value.material,
            rrp: resaleInformation.value.rrp,
            size: resaleInformation.value.size,
            sole_material: resaleInformation.value.sole_material,
            tags: resaleInformation.value.tags,
            width: resaleInformation.value.width,
          },
        };
        context.emit("moduleSubmitted", module);
      };

      if (predefinedModules) {
        selectedModule.value = predefinedModules[0].id;
        onSelectedModuleChange();
      }

      return {
        predefinedModules,
        selectedModule,
        onSelectedModuleChange,
        resaleInformation,
      };
    },
  });
