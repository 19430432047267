
import { defineComponent } from "vue";
import NavDesktopMenu from "@/components/NavMenu/NavDesktopMenu.vue"
import ContentContainer from"@/components/PageContainer/ContentContainer.vue"

export default defineComponent({
	name: "PageContainer",
	components: {
        NavDesktopMenu,
        ContentContainer
	},
	setup() {
	},
});
