
import { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";

export default defineComponent({
	name: "IconButton",
	props: {
		iconName: {
			required: false,
			type: String,
			default: "arrowLeft",
		},
	},
	components: {
		InlineSvg,
	},
	setup() {},
});
