
  import { defineComponent, PropType, ref, watchEffect } from "vue";
  import InlineSvg from "vue-inline-svg";
  import OutlineButton from "@/components/Buttons/OutlineButton.vue";
  import { Module } from "@/types/product/Module";
  import ItemTypeEnum from "@/enums/ItemTypeEnum";
  import { Product } from "@/types/product/Product";
  import NFTPreview from "@/components/NFTPreview/NFTPreview.vue";
  import CommissionChart from "@/components/ItemDetails/CommissionChart.vue";
  import InputNumber from "@/components/Input/InputNumber.vue";
  import useProduct from "@/hooks/useProduct";
  import { useRouter } from "vue-router";
  import { da } from "element-plus/lib/locale";

  export default defineComponent({
    name: "ItemModule",
    components: {
      InlineSvg,
      OutlineButton,
      NFTPreview,
      CommissionChart,
      InputNumber,
    },
    props: {
      module: {
        required: true,
        type: Object as PropType<Module>,
      },
    },
    setup(props) {
      const moduleValue = ref(props.module.value);
      const moduleType = ref(props.module.type);

      const router = useRouter();
      const { id } = router.currentRoute.value.params;

      const { data, status } = useProduct(id as string);
      watchEffect(() => {
        if (status.value === "success") {
          if (data.value?.type?.value)
            moduleValue.value = data.value?.type?.value;

          if (data.value?.type?.type) moduleType.value = data.value?.type?.type;
        }
      });

      return {
        moduleType,
        moduleValue,
        ItemTypeEnum,
      };
    },
  });
