
import { computed, defineComponent, watchEffect } from "vue";
import UserViewsContainer from "@/components/UserViewsContainer/UserViewsContainer.vue";
import UserHeader from "@/components/UserHeader/UserHeader.vue";
import Section from "@/components/Section/Section.vue";
import Product from "@/components/Product/Product.vue";
import ProductSlider from "@/components/ProductSlider/ProductSlider.vue";
import MetaverseAvatar from "@/components/MetaverseAvatar/MetaverseAvatar.vue";
import ActionButton from "@/components/Buttons/ActionButton.vue";
import { useStore } from "vuex";
import { UserGetters } from "@/store/user/getters";
import { useQuery } from "vue-query";
import productService from "@/services/productService";

export default defineComponent({
	name: "UserHomeView",
	components: {
		UserViewsContainer,
		UserHeader,
		Section,
		Product,
		ProductSlider,
		MetaverseAvatar,
		ActionButton,
	},
	setup() {
		const store = useStore();
		const user = computed(() => store.getters[UserGetters.GET_USER]);
		const dipslayName = computed(() => {
			if (!user.value?.name) {
				return "";
			}

			const whiteSpace = user.value.name.indexOf(" ");

			if (whiteSpace === -1) {
				return user.value.name;
			}

			return user.value.name.substr(0, whiteSpace);
		});

		const { data: products } = useQuery("userProducts", () =>
			productService.getUserProducts(user?.value?.id)
		);

		return { user, dipslayName, products };
	},
});
