
  import { defineComponent, watchEffect, ref } from "vue";
  import Loader from "@/components/Loader/Loader.vue";
  import Input from "@/components/Input/Input.vue";
  import itemDetailsSchema from "@/schemas/itemDetailsSchema";
  import { useForm, useField } from "vee-validate";
  import Button from "@/components/Buttons/Button.vue";
  import productService from "@/services/productService";
  import alertService from "@/services/alertService";
  import Vibrant from "node-vibrant";
  import Product from "@/components/Product/Product.vue";
  import { useRouter } from "vue-router";
  import useProduct from "@/hooks/useProduct";
  import ItemPreview from "@/components/ItemDetails/ItemPreview.vue";
  import { Product as ProductType } from "@/types/product/Product";
  import ItemTypeEnum from "@/enums/ItemTypeEnum";
  import EditModuleGreenCredentails from "@/components/EditItem/EditModuleGreenCredentails.vue";
  import EditModuleResale from "@/components/EditItem/EditModuleResale.vue";
  import { Module } from "@/types/product/Module";
  import DeleteButton from "@/components/Buttons/DeleteButton.vue";

  export default defineComponent({
    name: "EditItemForm",
    components: {
      Input,
      Button,
      Product,
      Loader,
      ItemPreview,
      EditModuleGreenCredentails,
      EditModuleResale,
      DeleteButton,
    },
    props: {
      submitEvent: {
        required: true,
        type: Boolean,
      },
    },
    setup(props, context) {
      const router = useRouter();

      const { id } = router.currentRoute.value.params;
      const { data, status } = useProduct(id as string);

      const { handleSubmit, errors, setValues } = useForm({
        validationSchema: itemDetailsSchema,
      });

      const color = ref("rgba(71, 66, 208, 0.4)");

      const { value: item_name } = useField<string>("item_name");
      const { value: item_photo } = useField<string>("item_photo");
      const { value: description } = useField<string>("description");

      const module = data.value?.type;
      const moduleType = module?.type;
      const moduleValue = module?.value;

      const editedModule = ref<Module>({
        type: moduleType || "",
        value: moduleValue || {},
      });

      watchEffect(() => {
        if (data.value && status.value === "success") {
          color.value = data.value.backgroundColor;
          setValues({
            item_name: data.value.name,
            item_photo: data.value.photoUrl,
            description: data.value.description ?? "",
          });
        }
      });

      const getColor = () => {
        const vibrant = new Vibrant(item_photo.value);

        vibrant.getPalette().then(
          (palette) => {
            const rgb = palette.Vibrant?.rgb || [71, 66, 208];
            color.value = `rgba(${Math.round(rgb[0])}, ${Math.round(
              rgb[1]
            )}, ${Math.round(rgb[2])}, 0.4)`;
          },
          (e) => {
            alertService.error(e);
          }
        );
      };

      const setModuleValue = (val: Module) => {
        editedModule.value = val;
      };

      const onSubmit = handleSubmit((values) => {
        const updatedFields = {
          description: values.description || "",
          name: values.item_name || "",
          photoUrl: values.item_photo || "",
          backgroundColor: color.value,
          type: editedModule.value,
        };
        handleUpdateItem(updatedFields);
      });

      const handleUpdateItem = async (values: Partial<ProductType>) => {
        try {
          const product = await productService.updateProduct(
            id as string,
            values
          );
          if (product?.id) {
            router.replace(`/item/${product.id}`);
          } else {
            router.replace(`/dashboard`);
          }
          alertService.success("Product updated successfully");
          context.emit("updated", true);
        } catch (e) {
          alertService.error(e);
        }
      };

      watchEffect(() => {
        if (props.submitEvent) {
          onSubmit();
          context.emit("submitted", true);
        }
      });

      const deleteItem = async () => {
        try {
          await productService.removeProduct(id as string);
          router.replace(`/dashboard`);
          alertService.success("Product has been removed");
        } catch (e) {
          alert("Error occured");
        }
      };

      return {
        onSubmit,
        item_name,
        item_photo,
        errors,
        color,
        getColor,
        data,
        status,
        module,
        moduleType,
        moduleValue,
        setModuleValue,
        ItemTypeEnum,
        deleteItem,
        description,
      };
    },
  });
