import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Pie = _resolveComponent("Pie")!

  return (_openBlock(), _createBlock(_component_Pie, {
    ref: "pie",
    "chart-options": _ctx.chartOptions,
    "chart-data": _ctx.chartData,
    "chart-id": "commisionChart",
    "dataset-id-key": 'label',
    height: _ctx.height,
    width: _ctx.width
  }, null, 8, ["chart-options", "chart-data", "height", "width"]))
}