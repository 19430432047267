
import { defineComponent, ref } from "vue";
import InlineSvg from "vue-inline-svg";
import Modal from "@/components/Modal/Modal.vue";
import Input from "@/components/Input/Input.vue";
import Button from "@/components/Buttons/Button.vue";
import CreateWardobeForm from "@/components/CreateWardobeForm/CreateWardobeForm.vue";
import ChangeMetawerseAvatarForm from "@/components/ChangeMetawerseAvatarForm/ChangeMetawerseAvatarForm.vue";

export default defineComponent({
	name: "ActionButton",
	components: {
		InlineSvg,
		Modal,
		Input,
		Button,
		CreateWardobeForm,
		ChangeMetawerseAvatarForm,
	},
	setup() {
		const isActive = ref(false);
		const isCreateWardobeModalOpened = ref(false);

		const toggleIsCreateWardobeModalOpened = () => {
			isCreateWardobeModalOpened.value = !isCreateWardobeModalOpened.value;
		};

		const isChangingMetawerseAvatar = ref(false);

		const toggleIsChangingMetawerseAvatar = () => {
			isChangingMetawerseAvatar.value = !isChangingMetawerseAvatar.value;
		};

		return {
			isActive,
			isCreateWardobeModalOpened,
			toggleIsCreateWardobeModalOpened,
			isChangingMetawerseAvatar,
			toggleIsChangingMetawerseAvatar,
		};
	},
});
