import { Wardobe } from "@/types/wardobe/Wardobe";

export const checkIsProductInWardobes = (
	wardobes: Wardobe[],
	productId: string
) => {
	return wardobes.some((wardobe) =>
		wardobe.products.some(({ id }) => id === productId)
	);
};
