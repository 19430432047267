import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76c4b782"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardProducts = _resolveComponent("DashboardProducts")!
  const _component_DashboardCollections = _resolveComponent("DashboardCollections")!
  const _component_MarketingStatistics = _resolveComponent("MarketingStatistics")!
  const _component_CollectionsRange = _resolveComponent("CollectionsRange")!
  const _component_PageContainer = _resolveComponent("PageContainer")!

  return (_openBlock(), _createBlock(_component_PageContainer, null, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_DashboardProducts)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_DashboardCollections)
        ]),
        _createVNode(_component_MarketingStatistics),
        _createVNode(_component_CollectionsRange)
      ])
    ]),
    _: 1
  }))
}