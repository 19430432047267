
  import { defineComponent } from "vue";
  import InlineSvg from "vue-inline-svg";

  export default defineComponent({
    name: "OutlineButton",
    props: {
      iconName: {
        required: true,
        type: String,
      },
    },
    components: {
      InlineSvg,
    },
    setup() {},
  });
