import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51efacd9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "heading" }
const _hoisted_2 = { class: "step-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["header", { 'has-margin': _ctx.hasMargin }])
  }, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.subTitle), 1)
  ], 2))
}