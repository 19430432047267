
  import { defineComponent } from "vue";
  import InlineSvg from "vue-inline-svg";

  export default defineComponent({
    name: "DeleteButton",
    components: {
      InlineSvg,
    },
    setup() {},
  });
