import { User } from "@/types/user/User";
import { GetterTree } from "vuex";
import { RootState } from "./../types";
import { UserState } from "./types";

export enum UserGetters {
	GET_USER = "GET_USER",
}

export const getters: GetterTree<UserState, RootState> = {
	[UserGetters.GET_USER]: (state: UserState): User | null => {
		return state.user;
	},
};
