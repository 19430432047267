
import { defineComponent } from "vue";
import router from "../../router/index";
import InlineSvg from "vue-inline-svg";
import NavButton from "@/components/Buttons/NavButton.vue";
import UserInfo from "@/components/NavMenu/UserInfo.vue";

export default defineComponent({
	name: "NavDesktopMenu",
	components: {
		InlineSvg,
		NavButton,
		UserInfo,
	},
	setup() {
		const onDashboard = () => {
			router.push("/dashboard");
		};
		return { onDashboard };
	},
});
