
import { defineComponent } from "vue";
import InlineSvg from "vue-inline-svg";

export default defineComponent({
	name: "LoginPageContainer",
	components: {
		InlineSvg,
	},
	setup() {
	},
});
