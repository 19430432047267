
import { defineComponent, PropType } from "vue";
import AccountTypeEnum from "@/enums/AccountTypeEnum"

export default defineComponent({
	name: "AccountSwitch",
	components: {
	},
    props: {
        account: {
            required: true,
            type: String as PropType<AccountTypeEnum>,
        }
    },
	setup() {
        
        return {AccountTypeEnum}
	},
});
