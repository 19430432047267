
import { defineComponent } from "vue";
import LoginPageContainer from "@/components/PageContainer/LoginPageContainer.vue"
import LoginForm from "@/components/LoginForm/LoginForm.vue"

export default defineComponent({
	name: "LoginView",
	components: {
        LoginPageContainer,
        LoginForm
	},
	setup() {
	},
});
