import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createBlock(_component_inline_svg, {
    src: require(`@/assets/${_ctx.data}Chart.svg`),
    height: "70px",
    width: "100%"
  }, null, 8, ["src"]))
}