
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "NFTPreview",
    components: {},
    props: {
      metaverseAvatar: {
        required: true,
        type: String,
      },
    },
    setup() {},
  });
