
import { defineComponent } from "vue";
import SectionTitle from "@/components/SectionTitle/SectionTitle.vue";
import Avatar from "@/components/Avatar/Avatar.vue";
import Modal from "@/components/Modal/Modal.vue";
import EditUserModal from "@/components/EditUserModal/EditUserModal.vue";
import useModal from "@/hooks/useModal";
export default defineComponent({
	name: "UserHeader",
	components: {
		SectionTitle,
		Avatar,
		Modal,
		EditUserModal,
	},
	props: {
		title: {
			required: false,
			type: String,
			default: "",
		},
		subTitle: {
			required: false,
			type: String,
		},
		imageSrc: {
			required: true,
			type: String,
		},
	},

	setup() {
		const { isOpen, toggleIsOpen } = useModal();

		return { isOpen, toggleIsOpen };
	},
});
