import authService from "@/services/authService";
import { User } from "@/types/user/User";
import { useMutation } from "vue-query";

interface Data {
	id: string;
	updatedFields: Partial<User>;
}

function useChangeUserDataMutation() {
	return useMutation((data: Data) =>
		authService.changeUserData(data.id, data.updatedFields)
	);
}

export default useChangeUserDataMutation;
