import { User } from "@/types/user/User";
import { MutationTree } from "vuex";
import { UserState } from "./types";

export enum UserMutations {
	SET_USER = "SET_USER",
}

export const mutations: MutationTree<UserState> = {
	[UserMutations.SET_USER](state, payload: User | null) {
		state.user = payload;
	},
};
