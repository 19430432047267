import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4794437b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slot-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_SectionTitle, {
      title: _ctx.title,
      subTitle: _ctx.subTitle,
      link: _ctx.link,
      centered: _ctx.centered
    }, null, 8, ["title", "subTitle", "link", "centered"]),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}