
import { defineComponent, PropType } from "vue";
import InlineSvg from "vue-inline-svg";
import OutlineButton from "@/components/Buttons/OutlineButton.vue";
import { Collection } from "@/types/collection/Collection";

export default defineComponent({
	name: "ItemCollection",
	components: { InlineSvg, OutlineButton },
	props: {
		collection: {
			required: false,
			type: Object as PropType<Collection> | undefined,
		},

		handleClick: {
			required: true,
			type: Function,
			default: () => {},
		},

		handleRemove: {
			required: true,
			type: Function,
			default: () => {},
		},
	},
	setup() {
		return {};
	},
});
