
import { computed, defineComponent } from "vue";
import Modal from "@/components/Modal/Modal.vue";
import { useField, useForm } from "vee-validate";
import ChangeMetawerseAvatarSchema from "@/schemas/ChangeMetawerseAvatarSchema";
import Input from "@/components/Input/Input.vue";
import Button from "@/components/Buttons/Button.vue";
import alertService from "@/services/alertService";
import authService from "@/services/authService";
import { useStore } from "vuex";
import { UserGetters } from "@/store/user/getters";
import { UserMutations } from "@/store/user/mutations";

export default defineComponent({
	name: "ChangeMetawerseAvatarForm",
	components: {
		Modal,
		Input,
		Button,
	},
	props: {
		callback: {
			type: Function,
			required: true,
		},
	},
	setup({ callback }) {
		const { handleSubmit, errors } = useForm({
			validationSchema: ChangeMetawerseAvatarSchema,
		});
		const { value: avatarUrl } = useField("avatarUrl");
		const store = useStore();
		const user = computed(() => store.getters[UserGetters.GET_USER]);
		const onSubmit = handleSubmit(async (values) => {
			try {
				const updatedUser = await authService.changeUserData(
					user.value.id as string,
					{
						metawerseAvatarUrl: values.avatarUrl,
					}
				);
				store.commit(UserMutations.SET_USER, updatedUser);
				alertService.success(`The metawerse awatar was changed`);
				callback();
			} catch (e) {
				alertService.error(e);
			}
		});

		return {
			errors,
			avatarUrl,
			onSubmit,
		};
	},
});
