
import { computed, defineComponent } from "vue";
import Button from "@/components/Buttons/Button.vue";
import alertService from "@/services/alertService";
import wardobeService from "@/services/wardobeService";
import { useStore } from "vuex";
import { UserGetters } from "@/store/user/getters";
import router from "@/router";
export default defineComponent({
	components: {
		Button,
	},
	props: {
		wardobeId: {
			type: String,
			required: true,
		},

		closeModal: {
			type: Function,
			required: true,
		},
	},
	name: "RemoveWardobeForm",
	setup({ wardobeId }) {
		const store = useStore();
		const user = computed(() => store.getters[UserGetters.GET_USER]);

		const handleRemove = async () => {
			try {
				await wardobeService.removeWardobe(wardobeId, user.value.id);

				router.replace("/wardobes");
			} catch (e) {
				alertService.error(e);
			}
		};

		return {
			handleRemove,
		};
	},
});
