import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21d143ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardSectionHeader = _resolveComponent("DashboardSectionHeader")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_Product = _resolveComponent("Product")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardSectionHeader, { title: 'Products' }),
    _createVNode(_component_swiper, {
      "slides-per-view": 'auto',
      "space-between": 20,
      class: "collections-container"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_swiper_slide, { class: "slide" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "add-product",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('add-new-item')))
            }, [
              _createElementVNode("img", {
                src: require(`@/assets/icons/add.svg`),
                alt: "add"
              }, null, 8, _hoisted_1)
            ])
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, ({ id, name, photoUrl, backgroundColor }) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, {
            key: id,
            class: "slide"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Product, {
                name: name,
                color: backgroundColor,
                photoUrl: photoUrl,
                id: id,
                link: '/item/'
              }, null, 8, ["name", "color", "photoUrl", "id"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}