import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed00ae4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-container" }
const _hoisted_2 = { class: "avatar" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "user-data" }
const _hoisted_5 = { class: "user-name" }
const _hoisted_6 = { class: "user-email" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.user?.profileImage,
        alt: "avatar"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.user?.name), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.user?.email), 1),
      _createElementVNode("div", {
        class: "logout",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.signOut && _ctx.signOut(...args)))
      }, "Log out")
    ])
  ]))
}