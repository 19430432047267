
  import { defineComponent, ref } from "vue";
  import InlineSvg from "vue-inline-svg";
  import ItemTypeEnum from "@/enums/ItemTypeEnum";

  export default defineComponent({
    name: "Step1",
    components: {
      InlineSvg,
    },
    props: {
      type: {
        required: true,
        type: String,
      },
    },
    setup(props) {
      const picked = ref(props.type);
      return { picked, ItemTypeEnum };
    },
  });
