import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d4eeada"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "activeContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateWardobeForm = _resolveComponent("CreateWardobeForm")!
  const _component_Modal = _resolveComponent("Modal")!
  const _component_ChangeMetawerseAvatarForm = _resolveComponent("ChangeMetawerseAvatarForm")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Modal, {
      isOpen: _ctx.isCreateWardobeModalOpened,
      onToggleModal: _ctx.toggleIsCreateWardobeModalOpened
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CreateWardobeForm, { callback: _ctx.toggleIsCreateWardobeModalOpened }, null, 8, ["callback"])
      ]),
      _: 1
    }, 8, ["isOpen", "onToggleModal"]),
    _createVNode(_component_Modal, {
      isOpen: _ctx.isChangingMetawerseAvatar,
      onToggleModal: _ctx.toggleIsChangingMetawerseAvatar
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ChangeMetawerseAvatarForm, { callback: _ctx.toggleIsChangingMetawerseAvatar }, null, 8, ["callback"])
      ]),
      _: 1
    }, 8, ["isOpen", "onToggleModal"]),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.isActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("button", {
                class: "actionButtonStyle smallActionButton",
                onClick: _cache[0] || (_cache[0] = ($event: any) => {
					_ctx.$router.push('qrcodescanner');
					_ctx.isActive = false;
				})
              }, [
                _createVNode(_component_inline_svg, {
                  src: require(`@/assets/icons/qrcode.svg`),
                  width: "20",
                  height: "20"
                }, null, 8, ["src"])
              ]),
              _createElementVNode("button", {
                class: "actionButtonStyle smallActionButton",
                onClick: _cache[1] || (_cache[1] = ($event: any) => {
					_ctx.toggleIsCreateWardobeModalOpened();
					_ctx.isActive = false;
				})
              }, [
                _createVNode(_component_inline_svg, {
                  src: require(`@/assets/icons/wardobe.svg`),
                  width: "20",
                  height: "20"
                }, null, 8, ["src"])
              ]),
              _createElementVNode("button", {
                class: "actionButtonStyle smallActionButton",
                onClick: _cache[2] || (_cache[2] = ($event: any) => {
					_ctx.toggleIsChangingMetawerseAvatar();
					_ctx.isActive = false;
				})
              }, [
                _createVNode(_component_inline_svg, {
                  src: require(`@/assets/icons/metawerse.svg`),
                  width: "20",
                  height: "20"
                }, null, 8, ["src"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("button", {
      class: "actionButtonPosition actionButtonStyle",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isActive = !_ctx.isActive))
    }, [
      _createVNode(_component_inline_svg, {
        src: require(`@/assets/icons/add.svg`),
        width: "25",
        height: "25"
      }, null, 8, ["src"])
    ])
  ], 64))
}