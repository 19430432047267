
import { defineComponent } from "vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default defineComponent({
	name: "Loader",
	props: {},
	components: {
		PulseLoader,
	},
	setup() {},
});
