
  import { computed, defineComponent } from "vue";
  import { useRouter } from "vue-router";
  import { UserGetters } from "@/store/user/getters";
  import { useStore } from "vuex";
  import { UserActions } from "@/store/user/actions";
  import alertService from "@/services/alertService";

  export default defineComponent({
    name: "UserInfo",
    setup() {
      const store = useStore();
      const user = computed(() => store.getters[UserGetters.GET_USER]);

      const router = useRouter();

      const signOut = async () => {
        try {
          await store.dispatch(UserActions.LOGOUT_USER);
          router.push("/login");
        } catch (e) {
          alertService.error(e);
        }
      };

      return { user, signOut };
    },
  });
