import AccountTypeEnum from "@/enums/AccountTypeEnum";
import { auth, firestore } from "@/firebase";
import wardobeService from "@/services/wardobeService";
import { LoginUserDTO } from "@/types/auth/dto/LoginUserDTO";
import { RegisterUserDTO } from "@/types/auth/dto/RegisterUserDTO";
import { User } from "@/types/user/User";

const getCurrUser = async (): Promise<User | null> => {
	const collection = await firestore
		.collection("users")
		.doc(auth.currentUser?.uid)
		.get();

	const user = collection.data() as Omit<User, "docId">;

	if (!user) {
		return null;
	}

	return { ...user, id: collection.id };
};

const loginUser = async (data: LoginUserDTO) => {
	const { email, password } = data;
	await auth.signInWithEmailAndPassword(email, password);
	const token = await auth.currentUser?.getIdToken();
	if (token) {
		localStorage.setItem("token", token);
	}
	return await getCurrUser();
};

const logOutUser = async () => {
	await auth.signOut();
};

const registerUser = async (data: RegisterUserDTO) => {
	const { email, password } = data;
	const { user } = await auth.createUserWithEmailAndPassword(email, password);

	if (user) {
		const { password, ...restData } = data;

		await firestore
			.collection("users")
			.doc(user.uid)
			.set({
				...restData,
				metawerseAvatarUrl:
					"https://i.ibb.co/4YZTvkt/Ready-Player-Me-Avatar-1.png",
			});

		if (data.accountType === AccountTypeEnum.user) {
			await wardobeService.createWardobe(
				{ name: "Wardobe", products: [], userId: user.uid },
				true
			);
		}
	}

	return await getCurrUser();
};

const changeUserData = async (id: string, updatedFields: Partial<User>) => {
	if (updatedFields?.email) {
		await auth.currentUser?.updateEmail(updatedFields.email);
	}

	await firestore.collection("users").doc(id.trim()).update(updatedFields);
	return await getCurrUser();
};

export default {
	getCurrUser,
	loginUser,
	registerUser,
	changeUserData,
	logOutUser,
};
