
  import { computed, defineComponent, ref } from "vue";
  import Loader from "@/components/Loader/Loader.vue";
  import ItemPhotos from "@/components/ItemDetails/ItemPhotos.vue";
  import ItemCollection from "@/components/ItemDetails/ItemCollection.vue";
  import ItemModule from "@/components/ItemDetails/ItemModule.vue";
  import ItemPreview from "@/components/ItemDetails/ItemPreview.vue";
  import QRCode from "@/components/ItemDetails/QRCode.vue";
  import OutlineButton from "@/components/Buttons/OutlineButton.vue";
  import InlineSvg from "vue-inline-svg";
  import { useRouter } from "vue-router";
  import Modal from "@/components/Modal/Modal.vue";
  import collectionService from "@/services/collectionService";
  import { useQuery } from "vue-query";
  import { useStore } from "vuex";
  import { UserGetters } from "@/store/user/getters";
  import alertService from "@/services/alertService";
  import { Collection } from "@/types/collection/Collection";
  import productService from "@/services/productService";
  import { Product } from "@/types/product/Product";

  export default defineComponent({
    name: "ItemDetails",
    components: {
      Loader,
      ItemPhotos,
      ItemCollection,
      ItemModule,
      InlineSvg,
      OutlineButton,
      ItemPreview,
      QRCode,
      Modal,
    },
    setup() {
      const router = useRouter();
      const { id } = router.currentRoute.value.params;

      const store = useStore();
      const user = computed(() => store.getters[UserGetters.GET_USER]);

      const isAddCollectionToProductOpen = ref(false);
      const toggleIsAddCollectionToProductOpen = () => {
        isAddCollectionToProductOpen.value =
          !isAddCollectionToProductOpen.value;
      };
      const {
        data: collections,
        status: collectionsStatus,
        refetch: refreshCollections,
      } = useQuery(["Collection", user.value?.id], () =>
        collectionService.getUserCollection(user.value?.id as string)
      );

      const {
        data,
        status,
        refetch: refreshProducts,
      } = useQuery(
        ["product", id],
        () => productService.getProduct(id as string),
        {
          refetchOnWindowFocus: true,
          refetchOnReconnect: true,
          retryOnMount: true,
          refetchOnMount: true,
        }
      );

      const addModule = () => {
        router.push(`/add-module/${id}`);
      };

      const openEdit = () => {
        router.push(`/edit-item/${id}`);
      };

      const handleCollectionClick = () => {
        toggleIsAddCollectionToProductOpen();
      };

      const handleRemoveCollection = async () => {
        try {
          await productService.removeProductFromCollection(
            data.value as Product,
            currCollection.value as Collection
          );
          refreshCollections.value();
          refreshProducts.value();
          alertService.success(
            "collection was successfully removed from product"
          );
        } catch (e) {
          alertService.error(e);
        }
      };

      const handleCollectionPick = async (collection: Collection) => {
        try {
          await productService.addProductToCollection(
            data.value as Product,
            collection
          );
          refreshCollections.value();
          refreshProducts.value();
          toggleIsAddCollectionToProductOpen();
          alertService.success("Product added to collection successfully");
        } catch (e) {
          alertService.error(e);
        }
      };

      const currCollection = computed(() =>
        collections.value?.find((item) => item?.id === data.value?.collectionId)
      );

      return {
        data,
        status,
        isAddCollectionToProductOpen,
        collections,
        currCollection,
        openEdit,
        addModule,
        toggleIsAddCollectionToProductOpen,
        handleCollectionClick,
        handleCollectionPick,
        handleRemoveCollection,
      };
    },
  });
