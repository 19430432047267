import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32d1f93e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container", { 'container-small': _ctx.small }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (
      _ctx.handleLink ? _ctx.$router.push(_ctx.link ? _ctx.link + _ctx.id : `/userProduct/${_ctx.id}`) : null
    ))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["info", { 'info-small': _ctx.small }]),
      style: _normalizeStyle({ backgroundColor: _ctx.color })
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["image-container", { 'image-container-small': _ctx.small }])
      }, [
        _createElementVNode("img", {
          src: _ctx.photoUrl,
          alt: _ctx.name
        }, null, 8, _hoisted_1)
      ], 2),
      _createElementVNode("h5", {
        class: _normalizeClass(["title", { 'title-small': _ctx.small }])
      }, _toDisplayString(_ctx.name), 3)
    ], 6)
  ], 2))
}