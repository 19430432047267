
import { defineComponent } from "vue";

export default defineComponent({
	name: "DashboardSectionHeader",
	props: {
		buttonTitle: {
			type: String,
			required: false,
			default: "See all",
		},
		buttonLink: {
			type: String,
			required: false,
			default: "",
		},
		title: {
			type: String,
			required: true,
		},
	},
	components: {},
	setup() {},
});
