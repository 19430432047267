
import router from "@/router";
import { computed, defineComponent, ref } from "vue";
import useProduct from "@/hooks/useProduct";
import Header from "@/components/Header/Header.vue";
import UserViewsContainer from "@/components/UserViewsContainer/UserViewsContainer.vue";
import Loader from "@/components/Loader/Loader.vue";
import Section from "@/components/Section/Section.vue";
import Product from "@/components/Product/Product.vue";
import Button from "@/components/Buttons/Button.vue";
import { KebabMenuItem } from "@/types/KebabMenuItem";
import { useQuery } from "vue-query";
import wardobeService from "@/services/wardobeService";
import { Product as ProductType } from "@/types/product/Product";
import { useStore } from "vuex";
import { UserGetters } from "@/store/user/getters";
import Modal from "@/components/Modal/Modal.vue";
import PickWardobeModal from "@/components/PickWardobeModal/PickWardobeModal.vue";
import { Wardobe } from "@/types/wardobe/Wardobe";
import alertService from "@/services/alertService";
import productService from "@/services/productService";

export default defineComponent({
	name: "ProductView",
	components: {
		Header,
		UserViewsContainer,
		Loader,
		Section,
		Product,
		Button,
		Modal,
		PickWardobeModal,
	},
	setup() {
		const { id } = router.currentRoute.value.params;
		const { data, status } = useProduct(id as string);
		const store = useStore();
		const user = computed(() => store.getters[UserGetters.GET_USER]);

		const isTransportModal = ref(false);
		const toggleIsTransportModal = () => {
			isTransportModal.value = !isTransportModal.value;
		};

		const isRemoveModal = ref(false);
		const toggleIsRemoveModal = () => {
			isRemoveModal.value = !isRemoveModal.value;
		};

		const {
			data: wardobes,
			status: wardobesStatus,
			refetch,
		} = useQuery(
			"wardobes",
			() => wardobeService.getWardobesByUser(user.value?.id),
			{
				retry: true,
			}
		);

		const presentWardobe = computed(() => {
			if (wardobes.value === undefined) {
				return null;
			}

			if (data.value?.id === undefined || data === undefined) {
				return null;
			}

			return wardobes.value.find((wardobe) => {
				return wardobe.products.find((item) => item.id === data.value?.id);
			});
		});

		const filteredWardobes = computed(() => {
			if (!presentWardobe || !presentWardobe.value || !wardobes.value) {
				return [];
			}

			return wardobes.value.filter(
				(item) => item.id !== presentWardobe.value?.id
			);
		});

		const pickWardobe = async (wardobe: Wardobe) => {
			try {
				await wardobeService.transportProductToDiffrentWardobe(
					presentWardobe.value as Wardobe,
					wardobe,
					data.value as ProductType
				);
				await refetch.value();
				toggleIsTransportModal();
				alertService.success(
					`${data.value?.name} was successfully transported to ${wardobe.name} `
				);
			} catch (e) {
				alertService.error(e);
				toggleIsTransportModal();
			}
		};

		const handleRemove = async () => {
			try {
				const updatedPresentWardobe = presentWardobe.value?.products.filter(
					(item) => item.id !== data.value?.id
				);
				await wardobeService.updateWardobe(presentWardobe.value?.id as string, {
					products: updatedPresentWardobe,
				});
				await productService.updateProduct(data.value?.id as string, {
					ownerId: "",
				});
				await refetch.value();

				alertService.success(`${data.value?.name} was successfully removed `);

				toggleIsRemoveModal();
			} catch (e) {
				toggleIsRemoveModal();
			}
		};

		const menu: KebabMenuItem[] = [
			{
				name: "change wardobe",
				action: () => {
					isTransportModal.value = true;
				},
			},
			{
				name: "remove product",
				action: () => {
					isRemoveModal.value = true;
				},
			},
		];

		return {
			filteredWardobes,
			wardobes,
			data,
			status,
			menu,
			presentWardobe,
			isTransportModal,
			toggleIsTransportModal,
			isRemoveModal,
			toggleIsRemoveModal,
			pickWardobe,
			handleRemove,
		};
	},
});
