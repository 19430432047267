
  import { defineComponent, ref } from "vue";

  export default defineComponent({
    name: "InputNumber",
    components: {},
    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        default: "",
      },
      inputValue: {
        type: Number,
        required: true,
      },
      stepValue: {
        type: Number,
        default: 1,
      },
      minValue: {
        type: Number,
        default: 0,
      },
    },
    emits: ["changed"],
    setup(props) {
      const value = ref(props.inputValue);

      return { value };
    },
  });
