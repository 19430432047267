import { Module } from "vuex";
import { RootState } from "./../types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { UserState } from "./types";

const state: UserState = {
	user: null,
};

export const user: Module<UserState, RootState> = {
	state,
	getters,
	mutations,
	actions,
};
