import { createStore } from "vuex";
import { RootState } from "./types";
import { user } from "./user/user";

const store = createStore<RootState>({
	state: {},
	modules: {
		user,
	},
});

export default store;
