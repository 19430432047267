import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f70ec93"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "detail-name"
}
const _hoisted_3 = { class: "preview-container" }
const _hoisted_4 = { class: "preview-element" }
const _hoisted_5 = { class: "product-container" }
const _hoisted_6 = {
  key: 0,
  class: "detail-name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ItemTile = _resolveComponent("ItemTile")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Preview"))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ItemTile, {
            name: _ctx.itemName,
            photoUrl: _ctx.photoUrl,
            color: _ctx.itemColor
          }, null, 8, ["name", "photoUrl", "color"])
        ]),
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Desktop"))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}