
import { defineComponent, ref, watchEffect } from "vue";
import Header from "@/components/Header/Header.vue";
import Button from "@/components/Buttons/Button.vue";
import SectionTitle from "@/components/SectionTitle/SectionTitle.vue";
import Modal from "@/components/Modal/Modal.vue";
import useModal from "@/hooks/useModal";
import Product from "@/components/Product/Product.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import ProductSlider from "@/components/ProductSlider/ProductSlider.vue";
import Section from "@/components/Section/Section.vue";
import Input from "@/components/Input/Input.vue";
import Schema from "@/schemas/exampleSchema";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { useForm, useField } from "vee-validate";
import authService from "@/services/authService";
import AccountTypeEnum from "@/enums/AccountTypeEnum";
import wardobeService from "@/services/wardobeService";
import { CreateWardobeDTO } from "@/types/wardobe/dto/createWardobeDTO";
import router from "@/router";
export default defineComponent({
	name: "HomeView",
	components: {
		Header,
		Button,
		SectionTitle,
		Modal,
		Product,
		Swiper,
		SwiperSlide,
		ProductSlider,
		Section,
		Input,
	},
	setup() {
		const { isOpen, toggleIsOpen } = useModal();
		const email = ref("");
		const password = ref("");
		const displayName = ref("");

		const field = ref("");

		const data = [
			{
				name: "MADAME COTTON & TECH JERSEY COAT",
				photoUrl: "https://i.ibb.co/cT9Wdhm/image-4-1-ccexpress-1.png",
				small: true,
			},
			{
				name: "MADAME COTTON & TECH JERSEY COAT",
				photoUrl: "https://i.ibb.co/cT9Wdhm/image-4-1-ccexpress-1.png",
				small: true,
			},
			{
				name: "MADAME COTTON & TECH JERSEY COAT",
				photoUrl: "https://i.ibb.co/cT9Wdhm/image-4-1-ccexpress-1.png",
				small: true,
			},
			{
				name: "MADAME COTTON & TECH JERSEY COAT",
				photoUrl: "https://i.ibb.co/cT9Wdhm/image-4-1-ccexpress-1.png",
				small: true,
			},
		];

		const { handleSubmit, errors } = useForm({ validationSchema: Schema });
		const { value: text } = useField("text");

		const onSubmit = handleSubmit((values) => {
			alert(JSON.stringify(values, null, 2));
		});

		watchEffect(() => {
			router.replace("/login");
		});

		const handleLogin = async (e: Event) => {
			try {
				e.preventDefault();

				const user = await authService.loginUser({
					email: email.value,
					password: password.value,
				});

				alert("Logged in");
			} catch (e) {
				alert(e);
			}
		};

		const handleRegister = async (e: Event) => {
			try {
				e.preventDefault();

				await authService.registerUser({
					email: email.value,
					password: password.value,
					name: displayName.value,
					accountType: AccountTypeEnum.user,
					profileImage: "https://randomuser.me/api/portraits/men/41.jpg",
				});

				alert("Registerd");
			} catch (e) {
				alert(e);
			}
		};

		const handleCreateWardobe = async () => {
			const dto: CreateWardobeDTO = {
				userId: "DqCTLll8B3ekccNSKaGSmyrazn23",
				name: "CreatedWardobe",
				products: [],
			};
			await wardobeService.createWardobe(dto, true);
		};

		return {
			isOpen,
			data,
			field,
			text,
			errors,
			email,
			displayName,
			password,
			toggleIsOpen,
			onSubmit,
			handleLogin,
			handleRegister,
			handleCreateWardobe,
		};
	},
});
