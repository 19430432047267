import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.isOpen)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "modal-overlay",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleModal && _ctx.toggleModal(...args)))
          }, [
            _createElementVNode("div", {
              class: "modal-content",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stopPropagation && _ctx.stopPropagation(...args)))
            }, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}