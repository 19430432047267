
  import { defineComponent } from "vue";
  import PageContainer from "@/components/PageContainer/PageContainer.vue";
  import ItemDetails from "@/components/ItemDetails/ItemDetails.vue";
  import { useRoute, useRouter } from "vue-router";

  export default defineComponent({
    name: "ItemView",
    components: {
      PageContainer,
      ItemDetails,
    },
    setup() {
      const route = useRoute();
      const router = useRouter();
      if (!route.params.id) router.push("/dashboard");
    },
  });
