
import { computed, defineComponent } from "vue";
import Product from "@/components/Product/Product.vue";
import DashboardSectionHeader from "@/components/DashboardSectionHeader/DashboardSectionHeader.vue";
import { useQuery } from "vue-query";
import { useStore } from "vuex";
import { UserGetters } from "@/store/user/getters";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import productService from "@/services/productService";
export default defineComponent({
	name: "DashboardProducts",
	components: {
		Product,
		DashboardSectionHeader,
		Swiper,
		SwiperSlide,
	},
	setup() {
		const store = useStore();
		const user = computed(() => store.getters[UserGetters.GET_USER]);

		const { data: products, status: productsStatus } = useQuery(
			["products", user.value?.id],
			() => productService.getUserProducts(user.value?.id as string, true)
		);

		return {
			products,
			productsStatus,
		};
	},
});
