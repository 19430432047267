
import { defineComponent } from "vue";
import Input from "@/components/Input/Input.vue";
import Button from "@/components/Buttons/Button.vue";
import EditWardobeSchema from "@/schemas/EditWardobeSchema";
import { useField, useForm } from "vee-validate";
import alertService from "@/services/alertService";
import wardobeService from "@/services/wardobeService";

export default defineComponent({
	components: {
		Input,
		Button,
	},
	props: {
		wardobeId: {
			type: String,
			required: true,
		},

		callback: {
			type: Function,
			required: true,
		},
	},
	name: "EditWardobeModal",
	setup({ callback, wardobeId }) {
		const { handleSubmit, errors } = useForm({
			validationSchema: EditWardobeSchema,
		});
		const { value: wardobeName } = useField("wardobeName");
		const onSubmit = handleSubmit(async (values) => {
			try {
				await wardobeService.updateWardobe(wardobeId, {
					name: values.wardobeName,
				});
				await callback();
				alertService.success("Your wardobe was changed");
			} catch (e) {
				alertService.error(e);
			}
		});

		return {
			errors,
			wardobeName,
			onSubmit,
		};
	},
});
