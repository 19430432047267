
  import { defineComponent } from "vue";
  import Button from "@/components/Buttons/Button.vue";
  import { useRouter } from "vue-router";

  export default defineComponent({
    name: "FinishStep",
    components: { Button },
    props: {
      id: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const router = useRouter();

      const redirectToItem = () => {
        router.replace(`/item/${props.id}`);
      };
      return { redirectToItem };
    },
  });
