
import { defineComponent, PropType } from "vue";
import { Collection as CollectionType } from "@/types/collection/Collection";

export default defineComponent({
	name: "Collection",
	props: {
		collection: {
			type: Object as PropType<CollectionType>,
			required: true,
		},
		redirect: {
			type: Boolean,
			default: false,
		},
	},
	setup() {},
});
