
  import { defineComponent } from "vue";
  import PageContainer from "@/components/PageContainer/PageContainer.vue";
  import EditItem from "@/components/EditItem/EditItem.vue";

  export default defineComponent({
    name: "EditItemView",
    components: {
      PageContainer,
      EditItem,
    },
    setup() {},
  });
