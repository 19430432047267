
  import { defineComponent, PropType, ref } from "vue";
  import Editor from "@tinymce/tinymce-vue";
  import { Module } from "@/types/product/Module";
  import ItemTypeEnum from "@/enums/ItemTypeEnum";
  import InputRange from "@/components/Input/InputRange.vue";
  import InputNumber from "@/components/Input/InputNumber.vue";
  import CheckboxInput from "@/components/Input/CheckboxInput.vue";
  import CommissionChart from "@/components/ItemDetails/CommissionChart.vue";

  export default defineComponent({
    name: "EditModuleResale",
    components: {
      editor: Editor,
      InputRange,
      InputNumber,
      CheckboxInput,
      CommissionChart,
    },
    props: {
      moduleValue: {
        required: true,
        type: Object as PropType<{
          commission: number;
          royalty: number;
          allowContent: boolean;
        }>,
      },
    },
    setup(props, context) {
      const commission = ref(props.moduleValue.commission);
      const onRangeChange = (value: number) => {
        commission.value = value;
        onChange();
      };

      const royalty = ref(props.moduleValue.royalty);
      const onRoyaltyChange = (value: number) => {
        royalty.value = value;
        onChange();
      };

      const allowContent = ref(props.moduleValue.allowContent);
      const onContentChange = (value: boolean) => {
        allowContent.value = value;
        onChange();
      };

      const examplePrice = ref(100);

      const onChange = () => {
        const module: Module = {
          type: ItemTypeEnum.resale,
          value: {
            commission: commission,
            royalty: royalty,
            allowContent: allowContent,
          },
        };
        context.emit("moduleSubmitted", module);
      };

      const onExamplePriceChange = (value: number) => {
        examplePrice.value = value;
      };

      return {
        examplePrice,
        onExamplePriceChange,
        commission,
        onRangeChange,
        royalty,
        onRoyaltyChange,
        allowContent,
        onContentChange,
      };
    },
  });
