
import { defineComponent } from "vue";
import LoginPageContainer from "@/components/PageContainer/LoginPageContainer.vue"
import RegisterForm from "@/components/LoginForm/RegisterForm.vue"

export default defineComponent({
	name: "RegisterView",
	components: {
        LoginPageContainer,
        RegisterForm
	},
	setup() {
	},
});
