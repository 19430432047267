import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-91857638"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
    (_ctx.buttonLink)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(_ctx.buttonLink)))
        }, _toDisplayString(_ctx.buttonTitle), 1))
      : _createCommentVNode("", true)
  ]))
}