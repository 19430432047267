import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34114296"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_EditUserModal = _resolveComponent("EditUserModal")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createVNode(_component_SectionTitle, {
        title: 'Hi ' + _ctx.title ?? 'Hi Michelle',
        subTitle: _ctx.subTitle ?? 'Nice to se you again'
      }, null, 8, ["title", "subTitle"]),
      _createVNode(_component_Avatar, {
        onAvatarClick: _ctx.toggleIsOpen,
        imageSrc: _ctx.imageSrc
      }, null, 8, ["onAvatarClick", "imageSrc"])
    ]),
    _createVNode(_component_Modal, {
      isOpen: _ctx.isOpen,
      onToggleModal: _ctx.toggleIsOpen
    }, {
      default: _withCtx(() => [
        _createVNode(_component_EditUserModal)
      ]),
      _: 1
    }, 8, ["isOpen", "onToggleModal"])
  ], 64))
}