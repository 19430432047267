import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserHeader = _resolveComponent("UserHeader")!
  const _component_Product = _resolveComponent("Product")!
  const _component_Section = _resolveComponent("Section")!
  const _component_ProductSlider = _resolveComponent("ProductSlider")!
  const _component_MetaverseAvatar = _resolveComponent("MetaverseAvatar")!
  const _component_UserViewsContainer = _resolveComponent("UserViewsContainer")!
  const _component_ActionButton = _resolveComponent("ActionButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_UserViewsContainer, null, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_component_UserHeader, {
          title: _ctx.dipslayName,
          key: _ctx.dipslayName && _ctx.user?.profileImage,
          imageSrc: _ctx.user?.profileImage
        }, null, 8, ["title", "imageSrc"])),
        _createVNode(_component_Section, { title: 'Your last item' }, {
          default: _withCtx(() => [
            (_ctx.products && _ctx.products?.length > 0)
              ? (_openBlock(), _createElementBlock("div", {
                  key: _ctx.products?.[0]?.id
                }, [
                  _createVNode(_component_Product, {
                    id: _ctx.products?.[0]?.id,
                    name: _ctx.products?.[0]?.name,
                    photoUrl: _ctx.products?.[0]?.photoUrl,
                    color: _ctx.products?.[0]?.backgroundColor,
                    small: false
                  }, null, 8, ["id", "name", "photoUrl", "color"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_1, "You don't have any product"))
          ]),
          _: 1
        }),
        _createVNode(_component_Section, {
          title: 'Your all items',
          subTitle: 'Click to go to wardobes',
          link: 'wardobes'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ProductSlider, {
              data: _ctx.products,
              isSmall: true
            }, null, 8, ["data"])
          ]),
          _: 1
        }),
        _createVNode(_component_Section, {
          title: 'Metawerse Avatar',
          subTitle: 'Your avatar'
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_MetaverseAvatar, {
              avatarUrl: _ctx.user?.metawerseAvatarUrl,
              key: _ctx.user?.metawerseAvatarUrl
            }, null, 8, ["avatarUrl"]))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ActionButton, {
      onBtnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('qrcodescanner')))
    })
  ], 64))
}