import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-44aaeb38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "editor-container" }
const _hoisted_2 = {
  key: 0,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editor = _resolveComponent("editor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_editor, {
      modelValue: _ctx.item_desc,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item_desc) = $event)),
      "api-key": "no-api-key",
      "initial-value": "Describe your item...",
      "model-events": "change keydown undo redo blur focus paste",
      onChange: _ctx.onChange,
      "output-format": "html",
      init: {
        plugins: 'lists link image table help fullscreen wordcount preview',
        height: 350,
        toolbar:
          'undo redo | styleselect styles | fontselect fontfamily fontsize fontsizeselect forecolor| bold italic | alignleft aligncenter alignright alignjustify | image backcolor | fullscreen preview',
      }
    }, null, 8, ["modelValue", "onChange"]),
    (_ctx.errors.item_desc)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.errors.item_desc), 1))
      : _createCommentVNode("", true)
  ]))
}