
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "Input",

    props: {
      id: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        default: "",
      },
      placeholder: {
        type: String,
        default: "",
      },
      type: {
        type: String,
        default: "text",
      },
      error: {
        type: String,
        default: "",
      },
      modelValue: {
        type: [String, Number],
        default: "",
      },
      style: {
        type: String,
        default: "",
      },
      maxLength: {
        type: Number,
        default: 100,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
  });
