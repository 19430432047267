
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import router from "./router";
import authService from "./services/authService";
import { UserMutations } from "./store/user/mutations";

export default defineComponent({
	setup() {
		const store = useStore();

		onMounted(async () => {
			const user = await authService.getCurrUser();

			if (!user) {
				router.replace("/login");
			}

			store.commit(UserMutations.SET_USER, user);
		});
	},
});
