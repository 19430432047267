
import { defineComponent } from "vue";

export default defineComponent({
	name: "Avatar",
	props: {
		imageSrc: {
			type: String,
			required: true,
		},
		big: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	setup() {},
});
