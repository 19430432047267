
  import { defineComponent } from "vue";
  import InlineSvg from "vue-inline-svg";

  export default defineComponent({
    name: "SvgLineChart",
    components: { InlineSvg },
    props: {
      data: {
        required: true,
        type: String,
      },
    },
    setup() {
      return {};
    },
  });
