import * as yup from "yup";

const itemDetailsSchema = yup.object({
  item_name: yup.string().required("Item name is required"),
  description: yup.string().required("Item description is required"),
  item_photo: yup
    .string()
    .required("Item photo link is required")
    .matches(
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
      "Invalid link"
    ),
});

export default itemDetailsSchema;
