import { ref } from "vue";

export default function useModal() {
	const isOpen = ref(false);
	const toggleIsOpen = () => {
		isOpen.value = !isOpen.value;
	};

	return { isOpen, toggleIsOpen };
}
