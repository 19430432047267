
import { defineComponent } from "vue";

export default defineComponent({
	name: "DashboardPageHeader",
	props: {
		title: {
			type: String,
			required: true,
		},
		subTitle: {
			type: String,
			required: true,
		},
		hasMargin: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	setup() {},
});
