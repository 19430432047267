import { auth } from "@/firebase";
import vClickOutside from "click-outside-vue3";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import { createApp } from "vue";
import { VueQueryPlugin } from "vue-query";
import VueQrcodeReader from "vue3-qrcode-reader";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import App from "./App.vue";
import router from "./router";
import store from "./store";
auth.onAuthStateChanged(async () => {
  createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus)
    .use(VueQueryPlugin)
    .use(VueQrcodeReader)
    .use(vClickOutside)
    .component(VueQrcode.name, VueQrcode)
    .mount("#app");
});
