
  import { defineComponent } from "vue";
  import PageContainer from "@/components/PageContainer/PageContainer.vue";
  import LinkButton from "@/components/Buttons/LinkButton.vue";
  import DashboardCollections from "@/components/DashboardCollections/DashboardCollections.vue";
  import DashboardProducts from "@/components/DashboardProducts/DashboardProducts.vue";
  import MarketingStatistics from "@/components/MarketingStatistics/MarketingStatistics.vue";
  import CollectionsRange from "@/components/CollectionsRange/CollectionsRange.vue";

  export default defineComponent({
    name: "DashboardView",
    components: {
      PageContainer,
      LinkButton,
      DashboardCollections,
      MarketingStatistics,
      DashboardProducts,
      CollectionsRange,
    },
    setup() {},
  });
