import { auth } from "@/firebase";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AddModuleView from "../views/AddModuleView.vue";
import AllWardobes from "../views/AllWardobes.vue";
import CollectionView from "../views/CollectionView.vue";
import DashboardView from "../views/DashboardView.vue";
import EditCollectionView from "../views/EditCollectionView.vue";
import EditItemView from "../views/EditItemView.vue";
import HomeView from "../views/HomeView.vue";
import ItemView from "../views/ItemView.vue";
import LoginView from "../views/LoginView.vue";
import NewItemView from "../views/NewItemView.vue";
import ProductCrudView from "../views/ProductCrudView.vue";
import ProductView from "../views/ProductView.vue";
import QrcodeScannerView from "../views/QrcodeScannerView.vue";
import RegisterView from "../views/RegisterView.vue";
import UserHomeView from "../views/UserHomeView.vue";
import UserView from "../views/UserView.vue";
import WardobeView from "../views/WardobeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { requiresAuth: false },
  },
  {
    path: "/register",
    name: "/register",
    component: RegisterView,
    meta: { requiresAuth: false },
  },
  {
    path: "/about",
    name: "about",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: UserView,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
    meta: { requiresAuth: true },
  },
  {
    path: "/add-new-item",
    name: "new-item",
    component: NewItemView,
    meta: { requiresAuth: true },
  },
  {
    path: "/edit-item/:id",
    name: "edit-item",
    component: EditItemView,
    meta: { requiresAuth: true },
  },
  {
    path: "/add-module/:id",
    name: "add-module",
    component: AddModuleView,
    meta: { requiresAuth: true },
  },
  {
    path: "/collection/:id",
    name: "collection",
    component: CollectionView,
    meta: { requiresAuth: true },
  },
  {
    path: "/item/:id",
    name: "item",
    component: ItemView,
    meta: { requiresAuth: true },
  },
  {
    path: "/userHomeView",
    name: "userHomeView",
    component: UserHomeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/qrcodescanner",
    name: "QrcodeScannerView",
    component: QrcodeScannerView,
    meta: { requiresAuth: true },
  },
  {
    path: "/userProduct/:id",
    name: "ProductView",
    component: ProductView,
    meta: { requiresAuth: true },
  },
  {
    path: "/wardobe/:id",
    name: "WardobeView",
    component: WardobeView,
    meta: { requiresAuth: true },
  },
  {
    path: "/wardobes",
    name: "Wardobes",
    component: AllWardobes,
    meta: { requiresAuth: true },
  },
  {
    path: "/edit-collection/:id",
    name: "Edit collection",
    component: EditCollectionView,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const currentUser = auth.currentUser;
  if (requiresAuth && !currentUser) {
    next("/login");
  } else if (!requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

export default router;
