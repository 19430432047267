
import { defineComponent } from "vue";
import Modal from "@/components/Modal/Modal.vue";
import { useField, useForm } from "vee-validate";
import CreateWardobeSchema from "@/schemas/CreateWardobeSchema";
import Input from "@/components/Input/Input.vue";
import Button from "@/components/Buttons/Button.vue";
import useAddNewWardobe from "@/hooks/useAddNewWardobe";
import alertService from "@/services/alertService";

export default defineComponent({
	name: "CreateWardobeForm",
	components: {
		Modal,
		Input,
		Button,
	},
	props: {
		callback: {
			type: Function,
			required: true,
		},
	},
	setup({ callback }) {
		const { mutateAsync } = useAddNewWardobe();

		const { handleSubmit, errors } = useForm({
			validationSchema: CreateWardobeSchema,
		});
		const { value: name } = useField("name");

		const onSubmit = handleSubmit(async (values) => {
			try {
				const { wardobe } = await mutateAsync(values.name as string);

				alertService.success(`The ${wardobe.name} was created`);

				callback();
			} catch (e) {
				alertService.error(e);
			}
		});

		return {
			errors,
			name,
			onSubmit,
		};
	},
});
