import { firestore } from "@/firebase";
import authService from "@/services/authService";
import { Product } from "@/types/product/Product";
import { Wardobe } from "@/types/wardobe/Wardobe";
import { CreateWardobeDTO } from "./../types/wardobe/dto/createWardobeDTO";

const createWardobe = async (
	wardobeDTO: CreateWardobeDTO,
	primary: boolean = false
): Promise<Wardobe> => {
	const wardobe = await firestore.collection("wardobes").add({
		...wardobeDTO,
		primary,
	});

	return await getWardobe(wardobe.id);
};

const createAndAddWardobeToUser = async (wardobeName: string) => {
	const user = await authService.getCurrUser();
	if (!user) {
		throw Error("No user");
	}
	const wardobe = await createWardobe({
		userId: user.id,
		name: wardobeName,
		products: [],
	});

	return {
		wardobe,
	};
};

const getWardobe = async (id: string): Promise<Wardobe> => {
	const collection = await firestore.collection("wardobes").doc(id).get();

	return {
		id: collection.id,
		...collection.data(),
	} as Wardobe;
};

const getWardobesByUser = async (userId: string): Promise<Wardobe[]> => {
	const collection = await firestore
		.collection("wardobes")
		.where("userId", "==", userId)
		.get();

	return collection.docs.map((item) => ({
		id: item.id,
		...item.data(),
	})) as Wardobe[];
};

const addProductToWardobe = async (wardobe: Wardobe, product: Product) => {
	await firestore
		.collection("wardobes")
		.doc(wardobe.id.trim())
		.update({ products: [...wardobe.products, product] });

	return await getWardobe(wardobe.id);
};

const updateWardobe = async (id: string, updatedFields: Partial<Wardobe>) => {
	await firestore.collection("wardobes").doc(id.trim()).update(updatedFields);
	return await getWardobe(id.trim());
};

const transportProductToDiffrentWardobe = async (
	fromWardobe: Wardobe,
	toWardobe: Wardobe,
	product: Product
) => {
	const updatedFromWardobe = {
		...fromWardobe,
		products: fromWardobe.products.filter((item) => item.id !== product.id),
	};
	const updatedToWardobe = {
		...toWardobe,
		products: [...toWardobe.products, product],
	};

	await updateWardobe(fromWardobe.id, updatedFromWardobe);
	await updateWardobe(toWardobe.id, updatedToWardobe);
};

const removeWardobe = async (id: string, userId: string) => {
	const wardobes = await getWardobesByUser(userId);
	const primaryWardobe = wardobes.find((wardobe) => wardobe.primary === true);
	const wardobeToRemove = wardobes.find((wardobe) => wardobe.id === id.trim());

	if (!wardobeToRemove) {
		throw new Error("No wardobe to remove");
	}

	if (wardobeToRemove.primary) {
		throw new Error("You can't remove primary wardobe");
	}

	if (!primaryWardobe) {
		throw new Error("You don' have primary wardobe");
	}

	await firestore.collection("wardobes").doc(id.trim()).delete();

	const updatedPrimaryWardobe: Wardobe = {
		...primaryWardobe,
		products: [...primaryWardobe?.products, ...wardobeToRemove?.products],
	};

	return await updateWardobe(updatedPrimaryWardobe.id, updatedPrimaryWardobe);
};

const getWardobeByProductId = async (
	product: Product
): Promise<Wardobe | null> => {
	const { buyedDate, ...restProduct } = product;

	const collection = await firestore
		.collection("wardobes")
		.where("products", "array-contains", { id: restProduct.id })
		.get();

	return collection.docs?.[0]?.data() as Wardobe;
};

export default {
	createWardobe,
	getWardobe,
	updateWardobe,
	removeWardobe,
	getWardobesByUser,
	addProductToWardobe,
	createAndAddWardobeToUser,
	getWardobeByProductId,
	transportProductToDiffrentWardobe,
};
