
  import { defineComponent, ref, watchEffect } from "vue";
  import { Pie } from "vue-chartjs";
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
    ChartData,
  } from "chart.js";

  ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

  export default defineComponent({
    name: "CommissionChart",
    components: { Pie },
    props: {
      commission: {
        required: true,
        type: Number,
      },
      royalty: {
        required: true,
        type: Number,
      },
      value: {
        required: true,
        type: Number,
      },
      width: {
        required: false,
        type: Number,
        default: 350,
      },
      height: {
        required: false,
        type: Number,
        default: 235,
      },
    },
    setup(props) {
      const calculateRoyaltyPercent = () => {
        return (props.royalty / props.value) * 100;
      };

      const calculateCustomersRevenue = () => {
        return 100 - (props.commission + calculateRoyaltyPercent());
      };

      const chartData = ref<ChartData<"pie">>({
        labels: [],
        datasets: [],
      });

      const fillData = () => {
        const updatedChartData = {
          labels: ["Commission", "Royalty", "Customer's revenue"],
          datasets: [
            {
              backgroundColor: ["#345995", "#DDA77B", "#3DDC97"],
              data: [
                props.commission,
                calculateRoyaltyPercent(),
                calculateCustomersRevenue(),
              ],
            },
          ],
        };

        chartData.value = { ...updatedChartData };
      };

      const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "right",
          },
        },
      };

      watchEffect(() => {
        if (props.value) {
          fillData();
        }
      });

      return { chartData, chartOptions };
    },
  });
