import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79d3a31b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-container" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "container",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirectToItem()))
  }, [
    _createElementVNode("div", {
      class: "info",
      style: _normalizeStyle({ backgroundColor: _ctx.color })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.photoUrl,
          alt: _ctx.name
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.name), 1)
    ], 4)
  ]))
}