
import { computed, defineComponent, ref } from "vue";
import DesignerPageContainer from "@/components/DesignerPageContainer/DesignerPageContainer.vue";
import DashboardPageHeader from "@/components/DashboardPageHeader/DashboardPageHeader.vue";
import { useQuery } from "vue-query";
import { useRoute } from "vue-router";
import productService from "@/services/productService";
import collectionService from "@/services/collectionService";
import Loader from "@/components/Loader/Loader.vue";
import router from "@/router";
import OutlineButton from "@/components/Buttons/OutlineButton.vue";
import ProductSlider from "@/components/ProductSlider/ProductSlider.vue";
import Modal from "@/components/Modal/Modal.vue";
import { useStore } from "vuex";
import { UserGetters } from "@/store/user/getters";
import Product from "@/components/Product/Product.vue";
import { Product as ProductType } from "@/types/product/Product";
import { Collection } from "@/types/collection/Collection";
import alertService from "@/services/alertService";
export default defineComponent({
	name: "CollectionView",
	components: {
		DesignerPageContainer,
		DashboardPageHeader,
		Loader,
		OutlineButton,
		ProductSlider,
		Modal,
		Product,
	},
	setup() {
		const {
			params: { id },
		} = useRoute();

		const store = useStore();
		const user = computed(() => store.getters[UserGetters.GET_USER]);

		const addProductModalIsOpen = ref(false);

		const toggleAddProductModalIsOpen = () => {
			addProductModalIsOpen.value = !addProductModalIsOpen.value;
		};

		const {
			data: products,
			status: productsStatus,
			refetch: refreshProducts,
		} = useQuery(["ProductsCollection", id], () =>
			productService.getProductsByCollectionId(id as string)
		);

		const { data: collection, status: collectionStatus } = useQuery(
			["Collection", id],
			() => collectionService.getCollection(id as string)
		);

		const {
			data: designerProducts,
			status: designerProductsStatus,
			refetch: refreshDesignerProducts,
		} = useQuery("products", () =>
			productService.getUserProducts(user.value?.id as string, true)
		);

		const productsWithNoCollections = computed(() =>
			designerProducts?.value?.filter(
				(item) => item?.collectionId?.trim() === ""
			)
		);

		const clickProduct = async (product: ProductType) => {
			try {
				await productService.addProductToCollection(
					product,
					collection.value as Collection
				);
				refreshProducts.value();
				refreshDesignerProducts.value();
				alertService.success("Product was added to collection sucesfully");
				toggleAddProductModalIsOpen();
			} catch (e) {
				alertService.error(e);
			}
		};

		const openEdit = () => {
			router.push(`/edit-collection/${id}`);
		};

		return {
			productsStatus,
			collectionStatus,
			collection,
			products,
			openEdit,
			addProductModalIsOpen,
			toggleAddProductModalIsOpen,
			productsWithNoCollections,
			clickProduct,
		};
	},
});
