
import { computed, defineComponent } from "vue";
import Avatar from "@/components/Avatar/Avatar.vue";
import { useForm, useField } from "vee-validate";
import EditProfileSchema from "@/schemas/editProfileSchema";
import Input from "@/components/Input/Input.vue";
import Button from "@/components/Buttons/Button.vue";
import useChangeUserDataMutation from "@/hooks/useChangeUserDataMutation";
import alertService from "@/services/alertService";
import { UserGetters } from "@/store/user/getters";
import { useStore } from "vuex";
import { UserActions } from "@/store/user/actions";
import { UserMutations } from "@/store/user/mutations";
export default defineComponent({
	name: "EditUserModal",
	components: {
		Avatar,
		Input,
		Button,
	},
	setup() {
		const store = useStore();
		const user = computed(() => store.getters[UserGetters.GET_USER]);

		const { handleSubmit, errors } = useForm({
			validationSchema: EditProfileSchema,
		});
		const { value: displayName } = useField("displayName");
		const { value: email } = useField("email");
		const { value: profileImage } = useField("profileImage");

		const { mutateAsync } = useChangeUserDataMutation();

		const onSubmit = handleSubmit(async (values) => {
			try {
				const id = user.value?.id;
				const updatedUser = await mutateAsync({
					id: id,
					updatedFields: values,
				});

				store.commit(UserMutations.SET_USER, updatedUser);

				alertService.success("Your data was changed");
			} catch (e) {
				alertService.error(e);
			}
		});

		const logout = async () => {
			try {
				await store.dispatch(UserActions.LOGOUT_USER);
			} catch (e) {
				alertService.error(e);
			}
		};

		return {
			onSubmit,
			logout,
			user,
			errors,
			displayName,
			email,
			profileImage,
		};
	},
});
