
import { defineComponent, PropType, ref } from "vue";
import IconButton from "@/components/Buttons/IconButton.vue";
import InlineSvg from "vue-inline-svg";
import { KebabMenuItem } from "@/types/KebabMenuItem";
export default defineComponent({
	name: "Header",
	props: {
		iconName: {
			required: false,
			type: String,
			default: "arrowLeft",
		},

		title: {
			required: true,
			type: String,
		},

		menu: {
			required: false,
			type: Array as PropType<KebabMenuItem[]>,
		},
	},
	components: {
		IconButton,
		InlineSvg,
	},
	setup() {
		const isMenuOpened = ref(false);

		const outsideClick = () => {
			isMenuOpened.value = false;
		};

		const menuItemClick = (callback: Function) => {
			callback();
			isMenuOpened.value = false;
		};

		return { isMenuOpened, outsideClick, menuItemClick };
	},
});
