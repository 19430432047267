
  import { defineComponent, watchEffect, ref, computed, Ref } from "vue";
  import Input from "@/components/Input/Input.vue";
  import Button from "@/components/Buttons/Button.vue";
  import { CreateProductDTO } from "@/types/product/dto/CreateProductDTO";
  import productService from "@/services/productService";
  import alertService from "@/services/alertService";
  import Vibrant from "node-vibrant";
  import Product from "@/components/Product/Product.vue";
  import { UserGetters } from "@/store/user/getters";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import ItemPreview from "@/components/ItemDetails/ItemPreview.vue";
  import { useQuery } from "vue-query";
  import { PredefinedItemSelect } from "@/types/product/PredefinedItem";

  export default defineComponent({
    name: "NewItemForm",
    components: {
      Input,
      Button,
      Product,
      ItemPreview,
    },
    props: {
      submitEvent: {
        required: true,
        type: Boolean,
      },
    },
    setup(props, context) {
      const router = useRouter();

      const store = useStore();
      const user = computed(() => store.getters[UserGetters.GET_USER]);

      const { data: predefinedItems, status: predefinedItemsStatus } = useQuery(
        ["PredefinedItems", user.value?.id],
        () => productService.getAllPredefinedItems()
      );

      const options: Ref<PredefinedItemSelect[]> = ref([]);
      const color: Ref<string> = ref("rgba(71, 66, 208, 0.4)");
      const selectedItem: Ref<string> = ref("");
      const itemName: Ref<string> = ref("");
      const itemPhoto: Ref<string> = ref("");
      const itemDescription: Ref<string> = ref("");
      const predefinedModule: Ref<string> = ref("");

      const getColor = () => {
        const vibrant = new Vibrant(itemPhoto.value);

        vibrant.getPalette().then(
          (palette) => {
            const rgb = palette.Vibrant?.rgb || [71, 66, 208];
            color.value = `rgba(${Math.round(rgb[0])}, ${Math.round(
              rgb[1]
            )}, ${Math.round(rgb[2])}, 0.4)`;
          },
          (e) => {
            alertService.error(e);
          }
        );
      };

      const handleCreateItem = async (values: CreateProductDTO) => {
        try {
          const newProduct = await productService.addNewProduct(values);
          if (newProduct?.id) {
            router.push(`/item/${newProduct.id}`);
          } else {
            router.push(`/dashboard`);
          }
          alertService.success("Product successfully created");
          context.emit("created", true);
        } catch (e) {
          alertService.error(e);
        }
      };

      watchEffect(() => {
        if (predefinedItemsStatus.value === "success") {
          if (predefinedItems.value) {
            options.value = predefinedItems.value?.map((item) => ({
              ...item,
              label: item.name,
              value: item.id,
            }));
          }
        }
      });

      watchEffect(() => {
        if (selectedItem.value !== "") {
          const item = predefinedItems.value?.find(
            (item) => item.id === selectedItem.value
          );
          if (item) {
            itemDescription.value = item.description;
            itemName.value = item.name;
            itemPhoto.value = item.item_photo;
            predefinedModule.value = item.predefinedModule;
            getColor();
          }
        }
      });

      watchEffect(() => {
        if (props.submitEvent) {
          handleCreateItem({
            predefinedModule: predefinedModule.value || "",
            description: itemDescription.value || "",
            name: itemName.value || "",
            photoUrl: itemPhoto.value || "",
            designerId: user.value.id,
            backgroundColor: color.value,
            collectionId: "",
            price: null,
            ownerId: "",
            type: {},
          });
          context.emit("submitted", true);
        }
      });

      return {
        itemName,
        itemDescription,
        itemPhoto,
        color,
        getColor,
        selectedItem,
        options,
      };
    },
  });
