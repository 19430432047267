import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardSectionHeader = _resolveComponent("DashboardSectionHeader")!
  const _component_Collection = _resolveComponent("Collection")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardSectionHeader, { title: 'Collections' }),
    _createVNode(_component_swiper, {
      "slides-per-view": 'auto',
      "space-between": 20,
      class: "collections-container"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collections, (collection) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, {
            key: collection?.id,
            class: "slide"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Collection, {
                collection: collection,
                redirect: true
              }, null, 8, ["collection"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}