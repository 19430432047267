import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78833e1b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  key: 0,
  class: "menu"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createVNode(_component_IconButton, {
      onBtnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('btn-click')))
    }),
    (_ctx.menu)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "menu-button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isMenuOpened = true))
        }, [
          _createVNode(_component_inline_svg, {
            src: require(`@/assets/icons/kebab.svg`),
            width: "27",
            height: "27"
          }, null, 8, ["src"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.isMenuOpened)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item) => {
                return (_openBlock(), _createElementBlock("button", {
                  onClick: () => _ctx.menuItemClick(item.action),
                  key: item.name,
                  class: "kebab-menu-item"
                }, _toDisplayString(item.name), 9, _hoisted_3))
              }), 128))
            ])), [
              [_directive_click_outside, _ctx.outsideClick]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}