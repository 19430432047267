
import { defineComponent } from "vue";

export default defineComponent({
	name: "Modal",
	props: {
		isOpen: {
			required: true,
			type: Boolean,
		},
	},
	setup(props, { emit }) {
		const toggleModal = (e: Event) => {
			emit("toggleModal");
		};

		const stopPropagation = (e: Event) => {
			e.stopPropagation();
		};

		return { toggleModal, stopPropagation };
	},
});
