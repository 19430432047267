
  import { computed, defineComponent } from "vue";
  import Collection from "@/components/Collection/Collection.vue";
  import DashboardSectionHeader from "@/components/DashboardSectionHeader/DashboardSectionHeader.vue";
  import { useQuery } from "vue-query";
  import collectionService from "@/services/collectionService";
  import { useStore } from "vuex";
  import { UserGetters } from "@/store/user/getters";
  import { Swiper, SwiperSlide } from "swiper/vue";
  import "swiper/scss";
  import "swiper/scss/navigation";
  import "swiper/scss/pagination";
  export default defineComponent({
    name: "DashboardCollections",
    components: {
      Collection,
      DashboardSectionHeader,
      Swiper,
      SwiperSlide,
    },
    setup() {
      const store = useStore();
      const user = computed(() => store.getters[UserGetters.GET_USER]);

      const { data: collections, status: collectionsStatus } = useQuery(
        ["Collections", user.value?.id],
        () => collectionService.getUserCollection(user.value?.id as string)
      );

      return {
        collections,
        collectionsStatus,
      };
    },
  });
