import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('btn-click')))
  }, [
    _createVNode(_component_inline_svg, {
      src: require(`@/assets/icons/${_ctx.iconName}.svg`),
      width: "25",
      height: "25"
    }, null, 8, ["src"])
  ]))
}