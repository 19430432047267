import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36d87062"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input_number = _resolveComponent("el-input-number")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(_component_el_input_number, {
        size: "large",
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        min: _ctx.minValue,
        step: _ctx.stepValue,
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('changed', _ctx.value)))
      }, null, 8, ["modelValue", "min", "step"])
    ])
  ], 64))
}