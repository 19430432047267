
  import { defineComponent } from "vue";
  import { Bar } from "vue-chartjs";
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
  } from "chart.js";

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
  );

  export default defineComponent({
    name: "BarChart",
    components: { Bar },
    props: {
      width: {
        required: false,
        type: Number,
        default: 350,
      },
      height: {
        required: false,
        type: Number,
        default: 235,
      },
    },
    setup(props) {
      const chartData = {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Resale statistics",
            backgroundColor: "#4742d099",
            data: [403, 253, 129, 398, 192, 427, 395, 473, 465, 271, 612, 411],
          },
        ],
      };

      const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "right",
          },
        },
      };

      return { chartData, chartOptions };
    },
  });
