
import { defineComponent } from "vue";
import SectionTitle from "@/components/SectionTitle/SectionTitle.vue";

export default defineComponent({
	name: "Section",
	components: {
		SectionTitle,
	},
	props: {
		title: {
			required: true,
			type: String,
		},
		subTitle: {
			required: false,
			type: String,
		},
		link: {
			required: false,
			type: String,
		},
		centered: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	setup() {},
});
