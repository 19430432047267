
import { defineComponent } from "vue";

export default defineComponent({
	name: "MetaverseAvatar",
	props: {
		avatarUrl: {
			type: String,
			default: "https://i.ibb.co/4YZTvkt/Ready-Player-Me-Avatar-1.png",
		},
	},
	setup() {},
});
