import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    style: _normalizeStyle(_ctx.style),
    class: _normalizeClass([{ fullWidth: _ctx.fullWidth }, { small: _ctx.small }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('btn-click')))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 6))
}