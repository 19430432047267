
  import { defineComponent, ref } from "vue";
  import registerSchema from "@/schemas/registerSchema";
  import Input from "@/components/Input/Input.vue";
  import { useForm, useField } from "vee-validate";
  import Button from "@/components/Buttons/Button.vue";
  import AccountSwitch from "@/components/LoginForm/AccountSwitch.vue";
  import AccountTypeEnum from "@/enums/AccountTypeEnum";
  import { RegisterUserDTO } from "@/types/auth/dto/RegisterUserDTO";
  import authService from "@/services/authService";
  import alertService from "@/services/alertService";
  import { useRouter } from "vue-router";

  export default defineComponent({
    name: "RegisterForm",
    components: {
      Input,
      Button,
      AccountSwitch,
    },
    setup() {
      const { handleSubmit, errors } = useForm({
        validationSchema: registerSchema,
      });
      const { value: name } = useField<string>("name");
      const { value: email } = useField<string>("email");
      const { value: password } = useField<string>("password");
      const router = useRouter();

      const onSubmit = handleSubmit((values) => {
        handleRegister({
          accountType: account.value,
          email: values.email || "",
          name: values.name || "",
          password: values.password || "",
          profileImage: "https://cdn-icons-png.flaticon.com/512/149/149071.png",
        });
      });

      const handleRegister = async (values: RegisterUserDTO) => {
        try {
          await authService.registerUser(values);
          alertService.success("Account successfully created");
          router.push("/login");
        } catch (e) {
          alertService.error(e);
        }
      };

      const account = ref(AccountTypeEnum.user);

      const accountToggle = (type: any) => {
        account.value = type;
      };

      return {
        onSubmit,
        name,
        email,
        password,
        errors,
        account,
        accountToggle,
      };
    },
  });
