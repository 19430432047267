import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_LoginPageContainer = _resolveComponent("LoginPageContainer")!

  return (_openBlock(), _createBlock(_component_LoginPageContainer, null, {
    default: _withCtx(() => [
      _createVNode(_component_LoginForm)
    ]),
    _: 1
  }))
}