
import { defineComponent } from "vue";

export default defineComponent({
	name: "SectionTitle",
	props: {
		title: {
			required: true,
			type: String,
		},
		subTitle: {
			required: false,
			type: String,
		},
		link: {
			required: false,
			type: String,
		},
		centered: {
			required: false,
			type: Boolean,
			default: false,
		},
	},
	setup() {},
});
