import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Product = _resolveComponent("Product")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    "slides-per-view": 'auto',
    "space-between": 20,
    class: "slider"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, ({ id, name, photoUrl, backgroundColor }, index) => {
        return (_openBlock(), _createBlock(_component_swiper_slide, {
          class: _normalizeClass(["slide", {
				'extra-margin': !_ctx.isSmall,
			}]),
          key: index
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Product, {
              id: id,
              name: name,
              photoUrl: photoUrl,
              small: _ctx.isSmall,
              color: backgroundColor,
              link: _ctx.link
            }, null, 8, ["id", "name", "photoUrl", "small", "color", "link"])
          ]),
          _: 2
        }, 1032, ["class"]))
      }), 128))
    ]),
    _: 1
  }))
}