import { ElMessage } from "element-plus";

const error = (message: any) => {
	ElMessage({
		showClose: true,
		message: message,
		type: "error",
		zIndex: 10000,
	});
};

const success = (message: any) => {
	ElMessage({
		showClose: true,
		message: message,
		type: "success",
		zIndex: 10000,
	});
};

export default {
	error,
	success,
};
