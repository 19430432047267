
  import { defineComponent } from "vue";
  import loginSchema from "@/schemas/loginSchema";
  import Input from "@/components/Input/Input.vue";
  import { useForm, useField } from "vee-validate";
  import Button from "@/components/Buttons/Button.vue";
  import authService from "@/services/authService";
  import alertService from "@/services/alertService";
  import { LoginUserDTO } from "@/types/auth/dto/LoginUserDTO";
  import { useRouter } from "vue-router";
  import { User } from "@/types/user/User";
  import AccountTypeEnum from "@/enums/AccountTypeEnum";
  import { UserMutations } from "@/store/user/mutations";
  import { useStore } from "vuex";

  export default defineComponent({
    name: "LoginForm",
    components: {
      Input,
      Button,
    },
    setup() {
      const { handleSubmit, errors } = useForm({
        validationSchema: loginSchema,
      });
      const { value: email } = useField("email");
      const { value: password } = useField("password");
      const router = useRouter();
      const store = useStore();

      const onSubmit = handleSubmit((values) => {
        handleLogin({
          email: values.email || "",
          password: values.password || "",
        });
      });

      const handleLogin = async (values: LoginUserDTO) => {
        try {
          const user: User | null = await authService.loginUser(values);
          if (!user) throw new Error();
          store.commit(UserMutations.SET_USER, user);

          if (user.accountType === AccountTypeEnum.producer) {
            router.push("/dashboard");
            return;
          } else router.push("/userHomeView");
        } catch (e) {
          alertService.error(e);
        }
      };

      return { onSubmit, email, password, errors };
    },
  });
