
import { defineComponent } from "vue";
import Product from "../Product/Product.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

export default defineComponent({
	name: "ProductSlider",
	components: {
		Swiper,
		SwiperSlide,
		Product,
	},
	props: {
		data: {
			required: true,
			type: Array,
		},
		isSmall: {
			required: false,
			type: Boolean,
			default: false,
		},
		link: {
			type: String,
			required: false,
		},
	},
	setup() {},
});
