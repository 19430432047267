import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23ad2794"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "statistics-container" }
const _hoisted_2 = { class: "chart-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardSectionHeader = _resolveComponent("DashboardSectionHeader")!
  const _component_BarChart = _resolveComponent("BarChart")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_DashboardSectionHeader, { title: 'Marketing statistics' }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BarChart, {
        height: 360,
        width: 600
      })
    ])
  ]))
}