
  import { defineComponent, ref } from "vue";

  export default defineComponent({
    name: "InputRange",
    components: {},
    props: {
      id: {
        type: String,
        required: true,
      },
      maxValue: {
        type: Number,
        required: false,
        defalut: 100,
      },
      label: {
        type: String,
        default: "",
      },
      inputValue: {
        type: Number,
        required: true,
      },
    },
    emits: ["changed"],
    setup(props) {
      const value = ref(props.inputValue);

      return { value };
    },
  });
