
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "Button",
    props: {
      fullWidth: {
        required: false,
        default: false,
        type: Boolean,
      },
      small: {
        required: false,
        default: false,
        type: Boolean,
      },
      style: {
        type: String,
        required: false,
        default: "",
      },
    },
    setup() {},
  });
