
  import { defineComponent, ref, computed, Ref, watchEffect } from "vue";
  import Step1 from "@/components/AddModuleForm/Step1.vue";
  import FinishStep from "@/components/AddModuleForm/FinishStep.vue";
  import GreenCredentailsModule from "@/components/AddModuleForm/GreenCredentailsModule.vue";
  import MetaverseModule from "@/components/AddModuleForm/MetaverseModule.vue";
  import ResaleModule from "@/components/AddModuleForm/ResaleModule.vue";
  import Button from "@/components/Buttons/Button.vue";
  import { useRouter } from "vue-router";
  import ItemTypeEnum from "@/enums/ItemTypeEnum";
  import InlineSvg from "vue-inline-svg";
  import productService from "@/services/productService";
  import alertService from "@/services/alertService";
  import { Module } from "@/types/product/Module";
  import { useQuery } from "vue-query";
  import { UserGetters } from "@/store/user/getters";
  import { useStore } from "vuex";
  import { PredefinedModuleSelect } from "@/types/product/PredefinedModule";
  import useProduct from "@/hooks/useProduct";

  export default defineComponent({
    name: "AddModuleForm",
    components: {
      Step1,
      GreenCredentailsModule,
      MetaverseModule,
      ResaleModule,
      Button,
      InlineSvg,
      FinishStep,
    },
    setup() {
      // Navigation
      const active = ref(0);
      const router = useRouter();
      const { id } = router.currentRoute.value.params;

      const next = () => {
        if (picked.value != "") {
          if (active.value++ > 1) router.push("/dashboard");
        }
      };

      const back = () => {
        if (active.value > 0) {
          active.value--;
        } else router.replace(`/item/${id}`);
      };

      // Get predefined item module
      const { data, status } = useProduct(id as string);

      const store = useStore();
      const user = computed(() => store.getters[UserGetters.GET_USER]);
      const predefinedModulesSelect: Ref<PredefinedModuleSelect[]> = ref([]);
      const filteredOptions: Ref<PredefinedModuleSelect[]> = ref([]);

      const { data: predefinedModules, status: predefinedModulesStatus } =
        useQuery(["PredefinedModules", user.value?.id], () =>
          productService.getAllPredefinedModules()
        );

      const picked = ref<string>("");

      const setItemType = (type: ItemTypeEnum) => {
        picked.value = type;
        filteredOptions.value = predefinedModulesSelect.value.filter(
          (module) => module.moduleType === type
        );
      };

      watchEffect(() => {
        if (
          status.value === "success" &&
          predefinedModulesStatus.value === "success"
        ) {
          if (predefinedModules.value) {
            predefinedModulesSelect.value = predefinedModules.value?.map(
              (module) => ({
                ...module,
                label: module.name,
                value: module.id,
              })
            );
          }
        }
      });

      watchEffect(() => {
        if (
          status.value === "success" &&
          data?.value?.predefinedModule &&
          predefinedModulesStatus.value === "success" &&
          predefinedModules?.value
        ) {
          const predefined = predefinedModules.value?.find(
            (module) => module?.id === data.value?.predefinedModule
          );
          if (predefined?.moduleType === ItemTypeEnum.greenCredentials)
            setItemType(ItemTypeEnum.greenCredentials);
          if (predefined?.moduleType === ItemTypeEnum.resale)
            setItemType(ItemTypeEnum.resale);
          if (predefined?.moduleType === ItemTypeEnum.metaverse)
            setItemType(ItemTypeEnum.metaverse);
        }
      });

      // Save module
      const module = ref<Module>();
      const setModuleValue = (val: Module) => {
        module.value = val;
      };

      const handleAddModule = async () => {
        try {
          if (module.value) {
            const product = await productService.updateProduct(id as string, {
              type: module.value,
            });
            if (product?.id) {
              next();
            } else {
              router.replace(`/dashboard`);
            }
            alertService.success("Module added successfully");
          }
        } catch (e) {
          alertService.error(e);
        }
      };

      return {
        picked,
        active,
        next,
        back,
        setItemType,
        ItemTypeEnum,
        setModuleValue,
        handleAddModule,
        id,
        filteredOptions,
        data,
        status,
      };
    },
  });
