
import { computed, defineComponent, ref, watchEffect } from "vue";
import Header from "@/components/Header/Header.vue";
import Button from "@/components/Buttons/Button.vue";
import Loader from "@/components/Loader/Loader.vue";
import { QrcodeStream } from "vue3-qrcode-reader";
import alertService from "@/services/alertService";
import useGetProductMutation from "@/hooks/useGetProductMutation";
import Product from "@/components/Product/Product.vue";
import { useStore } from "vuex";
import wardobeService from "@/services/wardobeService";
import { UserGetters } from "@/store/user/getters";
import { useQuery } from "vue-query";
import Modal from "@/components/Modal/Modal.vue";
import { Wardobe } from "@/types/wardobe/Wardobe";
import { Product as ProductType } from "@/types/product/Product";
import { checkIsProductInWardobes } from "@/helpers/checkIsProductInWardobes";
import PickWardobeModal from "@/components/PickWardobeModal/PickWardobeModal.vue";
import productService from "@/services/productService";

type State = "idle" | "scaneer" | "addProduct" | "productAdded";

export default defineComponent({
	components: {
		Header,
		Button,
		Modal,
		QrcodeStream,
		Loader,
		Product,
		PickWardobeModal,
	},
	name: "QrcodeScannerView",
	setup() {
		const isScannerOpened = ref(false);
		const state = ref<State>("idle");

		const store = useStore();
		const user = computed(() => store.getters[UserGetters.GET_USER]);
		const addedWardobe = ref<Wardobe | undefined>();

		const isWardobeModalOpened = ref(false);
		const toggleIsWardobeModalOpened = () => {
			isWardobeModalOpened.value = !isWardobeModalOpened.value;
		};

		const { data: wardobes, status: wardobesStatus } = useQuery(
			"wardobes",
			() => wardobeService.getWardobesByUser(user.value?.id),
			{
				retry: true,
			}
		);

		const pickWardobe = async (wardobe: Wardobe) => {
			try {
				const isProductAlreadInWardobes = checkIsProductInWardobes(
					wardobes.value as Wardobe[],
					product.value?.id as string
				);

				if (isProductAlreadInWardobes) {
					throw new Error("You aready have this product in your wardobes");
				}

				await productService.updateProduct(product.value?.id as string, {
					ownerId: user.value.id,
				});

				await wardobeService.addProductToWardobe(
					wardobe,
					product.value as ProductType
				);

				addedWardobe.value = wardobe;

				state.value = "productAdded";

				alertService.success(`Your new clothe was added to ${wardobe.name}`);
			} catch (e) {
				alertService.error(e);
			} finally {
				toggleIsWardobeModalOpened();
			}
		};

		const loading = ref(false);
		const decoded = ref();
		const errorMsg = ref();

		const productMutation = useGetProductMutation();
		const product = computed(() => productMutation.data.value);
		const productError = computed(() => productMutation.error.value);
		const productStatus = computed(() => productMutation.status.value);

		const onDecode = (decodeString: string) => {
			decoded.value = decodeString;
			decoded.value = JSON.parse(decoded.value);
		};

		const getProduct = async () => {
			try {
				await productMutation.mutate(decoded.value);
				state.value = "addProduct";
			} catch (e) {
				alertService.error(e);
			}
		};

		const onInit = async (promise: Promise<any>) => {
			loading.value = true;

			try {
				await promise;
			} catch (error: any) {
				if (error.name === "NotAllowedError") {
					errorMsg.value = "ERROR: you need to grant camera access permission";
				} else if (error.name === "NotFoundError") {
					errorMsg.value = "ERROR: no camera on this device";
				} else if (error.name === "NotSupportedError") {
					errorMsg.value = "ERROR: secure context required (HTTPS, localhost)";
				} else if (error.name === "NotReadableError") {
					errorMsg.value = "ERROR: is the camera already in use?";
				} else if (error.name === "OverconstrainedError") {
					errorMsg.value = "ERROR: installed cameras are not suitable";
				} else if (error.name === "StreamApiNotSupportedError") {
					errorMsg.value = "ERROR: Stream API is not supported in this browser";
				} else if (error.name === "InsecureContextError") {
					errorMsg.value =
						"ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
				} else {
					errorMsg.value = `ERROR: Camera error (${error.name})`;
				}
			} finally {
				loading.value = false;
			}
		};

		const drawOutline = (decodeData: any[], context: any) => {
			let points = [];

			for (let k in decodeData) {
				switch (k) {
					case "topLeftCorner":
						points[0] = decodeData[k];
						break;
					case "topRightCorner":
						points[1] = decodeData[k];
						break;
					case "bottomRightCorner":
						points[2] = decodeData[k];
						break;
					case "bottomLeftCorner":
						points[3] = decodeData[k];
						break;
				}
			}

			context.lineWidth = 3;
			context.strokeStyle = "red";
			context.beginPath();
			context.moveTo(points[0].x, points[0].y);

			for (const { x, y } of points) {
				context.lineTo(x, y);
			}

			context.lineTo(points[0].x, points[0].y);
			context.closePath();
			context.stroke();
		};

		watchEffect(() => {
			if (productError.value !== null) {
				state.value = "scaneer";
				productMutation.reset.value();
				alertService.error("Wrong qr code. Please scan again");
			}
		});

		return {
			onDecode,
			onInit,
			drawOutline,
			getProduct,
			toggleIsWardobeModalOpened,
			pickWardobe,
			wardobes,
			addedWardobe,
			wardobesStatus,
			isWardobeModalOpened,
			productError,
			state,
			product,
			productStatus,
			productMutation,
			isScannerOpened,
			decoded,
			loading,
			errorMsg,
		};
	},
});
