
  import { defineComponent, ref, Ref } from "vue";
  import NFTPreview from "@/components/NFTPreview/NFTPreview.vue";
  import { PredefinedModuleSelect } from "@/types/product/PredefinedModule";
  import ItemTypeEnum from "@/enums/ItemTypeEnum";
  import { Module } from "@/types/product/Module";

  export default defineComponent({
    name: "MetaverseModule",
    components: { NFTPreview },
    props: {
      options: {
        type: Array,
        required: true,
      },
    },
    setup(props, context) {
      const predefinedModules: PredefinedModuleSelect[] =
        props.options as PredefinedModuleSelect[];

      const selectedModule: Ref<string> = ref("");
      const metaverseAvatar: Ref<string> = ref("");

      const onSelectedModuleChange = () => {
        if (selectedModule.value !== "") {
          const module: any = predefinedModules?.find(
            (module) => module.id === selectedModule.value
          );
          if (
            module?.moduleValue &&
            module.moduleType === ItemTypeEnum.metaverse
          ) {
            metaverseAvatar.value = module.moduleValue?.metaverse || "";
            onChange();
          }
        }
      };

      const onChange = () => {
        const module: Module = {
          type: ItemTypeEnum.metaverse,
          value: {
            metaverse: metaverseAvatar.value,
          },
        };
        context.emit("moduleSubmitted", module);
      };

      if (predefinedModules) {
        selectedModule.value = predefinedModules[0].id;
        onSelectedModuleChange();
      }

      return {
        metaverseAvatar,
        predefinedModules,
        selectedModule,
        onSelectedModuleChange,
      };
    },
  });
