
  import { defineComponent } from "vue";
  import InlineSvg from "vue-inline-svg";

  export default defineComponent({
    name: "NavButton",
    props: {
      iconName: {
        required: false,
        type: String,
        default: "homeIcon",
      },
      text: {
        required: true,
        type: String,
      },
      active: {
        required: false,
        default: false,
        type: Boolean,
      },
    },
    components: {
      InlineSvg,
    },
    setup() {},
  });
