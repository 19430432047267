
import { defineComponent } from "vue";
import PageContainer from "@/components/PageContainer/PageContainer.vue";
export default defineComponent({
	components: {
		PageContainer,
	},
	name: "DesignerPageContainer",
	setup() {},
});
