
import { Wardobe } from "@/types/wardobe/Wardobe";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "PickWardobeModal",
	props: {
		wardobes: {
			type: Array as PropType<Wardobe[]>,
			required: true,
		},
		pickWardobe: {
			type: Function as PropType<(wardobe: Wardobe) => void>,
			required: true,
		},
	},
	setup() {},
});
