
  import { defineComponent } from "vue";
  import DashboardSectionHeader from "@/components/DashboardSectionHeader/DashboardSectionHeader.vue";
  import SvgLineChart from "@/components/CollectionsRange/SvgLineChart.vue";

  export default defineComponent({
    name: "CollectionsRange",
    components: { DashboardSectionHeader, SvgLineChart },
    props: {},
    setup() {
      const tableData = [
        {
          collection: "Winter",
          activity: "increase",
        },
        {
          collection: "Collection 2",
          activity: "stable",
        },
        {
          collection: "Collection 3",
          activity: "decrease",
        },
      ];

      return { tableData };
    },
  });
