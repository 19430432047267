
  import { Module } from "@/types/product/Module";
  import { defineComponent, PropType, ref, watchEffect } from "vue";
  import OutlineButton from "@/components/Buttons/OutlineButton.vue";
  import { useRouter } from "vue-router";
  import useProduct from "@/hooks/useProduct";

  export default defineComponent({
    name: "QRCode",
    components: { OutlineButton },
    props: {
      itemId: {
        required: true,
        type: String,
      },
      module: {
        required: true,
        type: Object as PropType<Module>,
      },
    },
    setup(props) {
      const dialogVisible = ref(false);

      const moduleValue = ref(props.module.value);
      const moduleType = ref(props.module.type);

      const router = useRouter();
      const { id } = router.currentRoute.value.params;

      const { data, status } = useProduct(id as string);
      watchEffect(() => {
        if (status.value === "success") {
          if (data.value?.type?.value)
            moduleValue.value = data.value?.type?.value;

          if (data.value?.type?.type) moduleType.value = data.value?.type?.type;
        }
      });

      return { moduleType, moduleValue, dialogVisible };
    },
  });
