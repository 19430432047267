import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87c47af6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["avatar", { 'avatar-big': _ctx.big }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('avatarClick')))
  }, [
    _createElementVNode("img", {
      src: _ctx.imageSrc,
      alt: "avatar"
    }, null, 8, _hoisted_1)
  ], 2))
}