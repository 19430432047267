import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c0da81b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "svg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('btn-click')))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_inline_svg, {
        src: require(`@/assets/icons/deleteIcon.svg`),
        width: "25",
        height: "25"
      }, null, 8, ["src"])
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}