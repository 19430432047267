import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8cf24a70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collection-image" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "name-container" }
const _hoisted_4 = { class: "detail-name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "collection-container",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirect ? _ctx.$router.push('/collection/' + _ctx.collection?.id) : null))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: _ctx.collection?.photoUrl,
        alt: "collection name"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.collection?.name), 1)
      ])
    ])
  ]))
}