
  import { defineComponent } from "vue";
  import Editor from "@tinymce/tinymce-vue";
  import { useField, useForm } from "vee-validate";
  import editItemDescriptionSchema from "@/schemas/editItemDescriptionSchema";
  import { Module } from "@/types/product/Module";
  import ItemTypeEnum from "@/enums/ItemTypeEnum";

  export default defineComponent({
    name: "EditModuleGreenCredentails",
    components: {
      editor: Editor,
    },
    props: {
      moduleValue: {
        required: true,
        type: String,
      },
    },
    setup(props, context) {
      const { handleSubmit, errors, setValues } = useForm({
        validationSchema: editItemDescriptionSchema,
      });

      const { value: item_desc } = useField("item_desc");
      setValues({
        item_desc: props.moduleValue,
      });

      const onChange = handleSubmit(() => {
        const module: Module = {
          type: ItemTypeEnum.greenCredentials,
          value: { description: item_desc },
        };
        context.emit("moduleSubmitted", module);
      });

      return { onChange, item_desc, errors };
    },
  });
