import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d313ab6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collections-range-container" }
const _hoisted_2 = { class: "table-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardSectionHeader = _resolveComponent("DashboardSectionHeader")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_SvgLineChart = _resolveComponent("SvgLineChart")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_DashboardSectionHeader, { title: 'Collections range' }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_table, {
        data: _ctx.tableData,
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "collection",
            label: "Collection",
            width: "200"
          }),
          _createVNode(_component_el_table_column, { label: "Range" }, {
            default: _withCtx((scope) => [
              _createVNode(_component_SvgLineChart, {
                data: scope.row.activity
              }, null, 8, ["data"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])
    ])
  ]))
}