import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_checkbox, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      label: _ctx.label,
      size: "large",
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('changed', _ctx.value)))
    }, null, 8, ["modelValue", "label"])
  ]))
}