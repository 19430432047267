import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea028e06"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "input-container" }
const _hoisted_3 = ["id", "value", "placeholder", "type", "maxlength", "disabled"]
const _hoisted_4 = {
  key: 1,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.style)
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "label",
          for: _ctx.id
        }, _toDisplayString(_ctx.label), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", _mergeProps({ id: _ctx.id }, _ctx.$attrs, {
        value: _ctx.modelValue,
        placeholder: _ctx.placeholder ? _ctx.placeholder : _ctx.label,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('changeInput', $event.target.value))),
        class: "input",
        type: _ctx.type,
        maxlength: _ctx.maxLength,
        disabled: _ctx.disabled
      }), null, 16, _hoisted_3)
    ]),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ], 4))
}