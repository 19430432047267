
  import { defineComponent } from "vue";

  export default defineComponent({
    name: "Product",
    props: {
      id: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      photoUrl: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        required: false,
        default: "rgba(192, 165, 145, 0.4)",
      },
      small: {
        type: Boolean,
        required: false,
        default: false,
      },
      link: {
        type: String,
        required: false,
      },

      handleLink: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    setup() {},
  });
