
  import { defineComponent } from "vue";
  import { useRouter } from "vue-router";

  export default defineComponent({
    name: "ItemTile",
    props: {
      id: {
        type: String,
        required: false,
      },
      name: {
        type: String,
        required: true,
        default: "",
      },
      photoUrl: {
        type: String,
        required: true,
        default: "",
      },
      color: {
        type: String,
        required: false,
        default: "rgba(192, 165, 145, 0.4)",
      },
    },
    setup(props) {
      const router = useRouter();

      const redirectToItem = () => {
        if (props.id) router.push(`/item/${props.id}`);
      };

      return { redirectToItem };
    },
  });
