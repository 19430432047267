import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditItem = _resolveComponent("EditItem")!
  const _component_PageContainer = _resolveComponent("PageContainer")!

  return (_openBlock(), _createBlock(_component_PageContainer, null, {
    default: _withCtx(() => [
      _createVNode(_component_EditItem)
    ]),
    _: 1
  }))
}