
import { defineComponent, ref } from "vue";
import Header from "@/components/Header/Header.vue";
import UserViewsContainer from "@/components/UserViewsContainer/UserViewsContainer.vue";
import Section from "@/components/Section/Section.vue";
import Product from "@/components/Product/Product.vue";
import router from "@/router";
import { useQuery } from "vue-query";
import wardobeService from "@/services/wardobeService";
import Loader from "@/components/Loader/Loader.vue";
import { KebabMenuItem } from "@/types/KebabMenuItem";
import Modal from "@/components/Modal/Modal.vue";
import EditWardobeModal from "@/components/EditWardobeModal/EditWardobeModal.vue";
import RemoveWardobeForm from "@/components/RemoveWardobeForm/RemoveWardobeForm.vue";
import { firestore } from "@/firebase";
export default defineComponent({
	name: "WardobeView",
	components: {
		Header,
		UserViewsContainer,
		Section,
		Product,
		Loader,
		Modal,
		EditWardobeModal,
		RemoveWardobeForm,
	},
	setup() {
		const isEditModalOpened = ref(false);
		const toggleIsEditModalOpened = () => {
			isEditModalOpened.value = !isEditModalOpened.value;
		};
		const isRemoveModalOpened = ref(false);
		const toggleIsRemoveModalOpened = () => {
			isRemoveModalOpened.value = !isRemoveModalOpened.value;
		};

		const { id } = router.currentRoute.value.params;

		const {
			data: wardobe,
			status,
			refetch,
		} = useQuery(["wardobe", id], () =>
			wardobeService.getWardobe(id as string)
		);

		const menu: KebabMenuItem[] = [
			{ name: "edit", action: () => toggleIsEditModalOpened() },
			{ name: "remove wardobe", action: () => toggleIsRemoveModalOpened() },
		];

		return {
			id,
			wardobe,
			status,
			menu,
			isEditModalOpened,
			refetch,
			isRemoveModalOpened,
			toggleIsRemoveModalOpened,
			toggleIsEditModalOpened,
		};
	},
});
