import authService from "@/services/authService";
import { ActionTree } from "vuex";
import { RootState } from "./../types";
import { UserMutations } from "./mutations";
import { UserState } from "./types";

export enum UserActions {
  GET_CURRENT_USER = "GET_CURRENT_USER",
  LOGOUT_USER = "LOGOUT_USER",
}

export const actions: ActionTree<UserState, RootState> = {
  [UserActions.GET_CURRENT_USER]: async ({ commit }) => {
    const user = await authService.getCurrUser();

    commit(UserMutations.SET_USER, user);
    return user;
  },
  [UserActions.LOGOUT_USER]: async ({ commit }) => {
    await authService.logOutUser();
    commit(UserMutations.SET_USER, null);
  },
};
