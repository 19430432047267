import { firestore, Timestamp } from "@/firebase";
import { CreateProductDTO } from "@/types/product/dto/CreateProductDTO";
import { PredefinedItem } from "@/types/product/PredefinedItem";
import { PredefinedModule } from "@/types/product/PredefinedModule";
import Module from "module";
import { Collection } from "./../types/collection/Collection";
import { Product } from "./../types/product/Product";
import collectionService from "./collectionService";

const getAllProducts = async (): Promise<Product[] | undefined> => {
  const collection = await firestore.collection("products").get();

  return collection.docs.map(
    (product) => ({ ...product.data(), id: product.id } as Product)
  );
};

const getUserProducts = async (
  userId: string,
  isDesigner: boolean = false
): Promise<Product[] | undefined> => {
  const collection = await firestore
    .collection("products")
    .where(isDesigner ? "designerId" : "ownerId", "==", userId.trim())
    .get();

  return collection.docs
    .map(
      (product) =>
        ({
          ...product.data(),
          id: product.id,
          buyedDate: (product.data().buyedDate as any).toDate().toString(),
        } as Product)
    )
    .sort((a, b) => {
      return new Date(b.buyedDate).getTime() - new Date(a.buyedDate).getTime();
    });
};

const getProductsByCollectionId = async (
  collectionId: string
): Promise<Product[] | undefined> => {
  const collection = await firestore
    .collection("products")
    .where("collectionId", "==", collectionId.trim())
    .get();

  return collection.docs
    .map(
      (product) =>
        ({
          ...product.data(),
          id: product.id,
          buyedDate: (product.data().buyedDate as any).toDate().toString(),
        } as Product)
    )
    .sort((a, b) => {
      return new Date(b.buyedDate).getTime() - new Date(a.buyedDate).getTime();
    });
};

const getProduct = async (id: string): Promise<Product | undefined> => {
  const collection = await firestore
    .collection("products")
    .doc(id.trim())
    .get();
  return { ...collection.data(), id: collection.id } as Product | undefined;
};

const addNewProduct = async (
  product: CreateProductDTO
): Promise<Product | undefined> => {
  const collection = await firestore
    .collection("products")
    .add({ ...product, buyedDate: Timestamp.now() });
  return await getProduct(collection.id);
};

const updateProduct = async (
  id: string,
  updatedFields: Partial<Product>
): Promise<Product | undefined> => {
  await firestore.collection("products").doc(id.trim()).update(updatedFields);

  return await getProduct(id);
};

const removeProduct = async (id: string): Promise<void> => {
  await firestore.collection("products").doc(id.trim()).delete();
};

const addProductToCollection = async (
  product: Product,
  collection: Collection
) => {
  await updateProduct(product.id, { collectionId: collection.id });
  await collectionService.updateCollection(collection.id, {
    productsIds: [...collection.productsIds, product.id],
  });
};

const removeProductFromCollection = async (
  product: Product,
  collection: Collection
) => {
  await updateProduct(product.id, { collectionId: "" });
  await collectionService.updateCollection(collection.id, {
    productsIds: collection.productsIds.filter((id) => id !== product.id),
  });
};

const getAllPredefinedItems = async (): Promise<
  PredefinedItem[] | undefined
> => {
  const collection = await firestore.collection("predefined_items").get();

  return collection.docs.map(
    (item) => ({ ...item.data(), id: item.id } as PredefinedItem)
  );
};

const getAllPredefinedModules = async (): Promise<
  PredefinedModule[] | undefined
> => {
  const modules = await firestore.collection("predefined_modules").get();

  return modules.docs.map(
    (module) => ({ ...module.data(), id: module.id } as PredefinedModule)
  );
};

export default {
  getAllProducts,
  getUserProducts,
  getProductsByCollectionId,
  getProduct,
  addNewProduct,
  addProductToCollection,
  updateProduct,
  removeProductFromCollection,
  removeProduct,
  getAllPredefinedItems,
  getAllPredefinedModules,
};
