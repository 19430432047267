
  import { defineComponent } from "vue";
  import BarChart from "@/components/MarketingStatistics/BarChart.vue";
  import DashboardSectionHeader from "@/components/DashboardSectionHeader/DashboardSectionHeader.vue";

  export default defineComponent({
    name: "MarketingStatistics",
    components: { BarChart, DashboardSectionHeader },
    props: {},
    setup() {},
  });
