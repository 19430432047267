
  import { defineComponent, ref } from "vue";
  import NewItemForm from "@/components/NewItem/NewItemForm.vue";
  import Button from "@/components/Buttons/Button.vue";
  import { useRouter } from "vue-router";

  export default defineComponent({
    name: "NewItem",
    components: {
      NewItemForm,
      Button,
    },
    setup() {
      // Navigation
      const router = useRouter();

      const back = () => {
        router.back();
      };

      // Create item submit
      const submitEvent = ref(false);

      const createItem = () => {
        submitEvent.value = true;
      };

      const submitted = () => {
        submitEvent.value = false;
      };

      return {
        back,
        submitEvent,
        createItem,
        submitted,
      };
    },
  });
